import { Component } from 'react';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';

import heroImage from 'images/hero.jpg';

import './BuySuccess.scss';

class BuySuccess extends Component {
  render() {
    return (
      <div>
        <MobileNavigation />
        <Header />
        <div className="unitDetail container">
          <div className="unitDetail__hero">
            <div className="unitDetail__hero__data">
              <h1>Kicks Advance MT</h1>
              <p>
                Nafta. Manual 5 velocidades. <br />
                Color Rojo. Ubicado en Mendoza.
              </p>
              <Button arrow color="white">
                Ver caracteristicas
              </Button>
            </div>
            <div className="unitDetail__hero__image">
              <img src={heroImage} alt="Kicks" />
            </div>
          </div>
          <div className="unitDetail__buy">
            <div className="unitDetail__buyHelp">
              <h2>¡Felicitaciones!</h2>
              <p>
                <strong>Compraste este Kicks Advance MT.</strong>
              </p>
              <p>
                Recuerda que tienes 48 horas para visitar nuestras oficinas y
                confirmar la compra. Te esperamos en San Martín Sur 600, Godoy
                Cruz, Mendoza.
              </p>
              <p>Cualquier duda que tengas llamanos al 810 666 1033.</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BuySuccess;
