import Button from 'components/atoms/Button';

import './Message.scss';

const Message = ({ title, detail, type, actionText, actionLink }) => (
  <div className={'message ' + (type ? 'message-' + type : '')}>
    <div className="message__title">{title}</div>
    {detail && <div className="message__detail"> {detail} </div>}
    {actionText && (
      <div className="message__actions">
        <Button color="red" onClick={actionLink}>
          {actionText}
        </Button>{' '}
      </div>
    )}
  </div>
);

export default Message;
