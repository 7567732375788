import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
import NoPlan from 'containers/PlanBuy/NoPlan';
import PlanTable from 'components/organisms/PlanTable';
import { fetchPlanWithDetail, initSubscription } from 'actions/plans';
import { currency } from 'helpers/format';
import { getHero as getVehicleImage } from 'helpers/vehicle';

import './PlanInstallmentsDetail.scss';

class PlanInstallmentsDetail extends Component {
  componentDidMount() {
    const { plan } = this.props;

    if (
      !plan ||
      plan.id !== this.props.match.params.id ||
      !plan.installment_list
    ) {
      this.props.fetchPlan(this.props.match.params.id);
    }
  }

  render() {
    const { plan, loading } = this.props;

    if (loading || plan === undefined) {
      return <Loader />;
    }

    if (plan === null) {
      return this.renderNoPlan();
    }

    const heroImage = getVehicleImage(plan.family);

    return (
      <div>
        <MobileNavigation />
        <Header />

        <div className="planInstallmentsDetail container">
          <div className="planInstallmentsDetail__hero">
            <div className="planInstallmentsDetail__hero__data">
              <h4>Nissan Plan de Ahorro | {plan.name}</h4>

              <h1>Detalle de cuotas</h1>
              <br />
              <NavLink to={plan.subscribe_link}>
                <Button arrow>Suscribirse a este plan</Button>
              </NavLink>

              <NavLink to={plan.simulate_link}>
                <Button arrow color="gray">
                  Simular aumento de cuotas
                </Button>
              </NavLink>
              <NavLink to={plan.detail_link}>
                <Button back arrow color="gray">
                  Volver al plan
                </Button>
              </NavLink>
            </div>
            <div className="planInstallmentsDetail__hero__image">
              <img src={heroImage} alt={plan.family} />
            </div>
          </div>
          <hr />
          <div className="planInstallmentsDetail__buy">
            <div className="planInstallmentsDetail__buyHelp">
              <h2>Revisá el valor de cada cuota</h2>
              <p>
                Las cuotas pueden varían si el <strong>valor móvil</strong> del
                vehículo varía.
              </p>
            </div>
            <div className="planInstallmentsDetail__buyInfoMargin" />
            <div className="planInstallmentsDetail__buyInfo">
              <h5>Plan </h5>
              <h1>{plan.name}</h1>
            </div>
            <div className="planInstallmentsDetail__buyInfo">
              <h5>Valor Móvil Financiado </h5>
              <h1>{currency(plan.vehicle_price)}</h1>
            </div>
            <div className="planInstallmentsDetail__buyInfoMargin" />
            <div className="planInstallmentsDetail__buyInfoMargin" />
            <div className="planInstallmentsDetail__installmentsTable">
              <PlanTable>
                {plan.installment_list.map(installment => (
                  <div
                    className="priceTable__row"
                    key={installment.installment}
                  >
                    <div className="priceTable__item">
                      Cuota {installment.installment}
                    </div>
                    <div className="priceTable__price">
                      {currency(installment.amount)}
                    </div>
                  </div>
                ))}
              </PlanTable>
            </div>

            <hr />
            <div className="planInstallmentsDetail__buyCTA">
              <NavLink to={plan.detail_link}>
                <Button back arrow color="gray">
                  Volver al plan
                </Button>
              </NavLink>
              <NavLink to={plan.simulate_link}>
                <Button arrow color="gray">
                  Simular aumento de cuotas
                </Button>
              </NavLink>
              <NavLink to={plan.subscribe_link}>
                <Button arrow>Suscribirse a este plan</Button>
              </NavLink>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }

  renderNoPlan() {
    return <NoPlan />;
  }
}

const mapStateToProps = state => {
  return {
    plan: state.plans.plan,
    loading: state.plans.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlan: id => {
      dispatch(initSubscription());
      dispatch(fetchPlanWithDetail(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanInstallmentsDetail);
