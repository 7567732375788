import { currency } from 'helpers/format';
import { calculatePrice } from 'helpers/vehicle';

export default ({ vehicle, children, showTotal, promotion }) => (
  <div className="priceTable">
    <div className="priceTable__row">
      <div className="priceTable__item">Precio de lista</div>
      <div className="priceTable__price">{currency(vehicle.price)}</div>
    </div>
    <div className="priceTable__row">
      <div className="priceTable__item">Patentamiento</div>
      <div className="priceTable__price">{currency(vehicle.license_cost)}</div>
    </div>
    <div className="priceTable__row">
      <div className="priceTable__item">Entrega</div>
      <div className="priceTable__price">
        {currency(vehicle.preparation_cost)}
      </div>
    </div>
    {vehicle.special_discount === '0' && vehicle.web_discount > 0 && (
      <div className="priceTable__row">
        <div className="priceTable__item">Descuento web</div>
        <div className="priceTable__price">
          -{currency(vehicle.web_discount)}
        </div>
      </div>
    )}
    {vehicle.special_discount > 0 && (
      <div className="priceTable__row">
        <div className="priceTable__item">Descuento especial</div>
        <div className="priceTable__price">
          -{currency(vehicle.special_discount)}
        </div>
      </div>
    )}
    {vehicle.model_year_discount > 0 && (
      <div className="priceTable__row">
        <div className="priceTable__item">Descuento por MY</div>
        <div className="priceTable__price">
          -{currency(vehicle.model_year_discount)}
        </div>
      </div>
    )}
    {promotion && (
      <div className="priceTable__row">
        <div className="priceTable__item">Código de descuento</div>
        <div className="priceTable__price">
          -
          {promotion.money_discount > 0
            ? currency(promotion.money_discount)
            : currency((vehicle.price * promotion.percentage_discount) / 100)}
        </div>
      </div>
    )}
    {showTotal && (
      <div className="priceTable__row">
        <div className="priceTable__item">Total</div>
        <div className="priceTable__price">
          {currency(calculatePrice(vehicle, promotion))}
        </div>
      </div>
    )}
    {children}
  </div>
);
