import { Component } from 'react';
import closeImage from 'images/menu_on.svg';
import './UnitsContainer.scss';

class UnitsContainer extends Component {
  render() {
    var arrowPosition = this.props.arrowPosition;
    /* arrowPosition = "left", "center", "right"  */

    return (
      <div
        className={
          'unitsContainer ' +
          (arrowPosition ? 'unitsContainer-' + arrowPosition : '')
        }
      >
        <div className="unitsContainer__content">
          <div className="unitsContainer__mobile-title">
            <div className="unitsContainer__mobile-title__container">
              <h2>{this.props.vehicle}</h2>
              {this.props.units > 1 && (
                <h6>{this.props.units} Unidades disponibles</h6>
              )}
              {this.props.units === 1 && <h6>1 Unidad disponible</h6>}
              {this.props.units === 0 && <h6>Sin stock</h6>}
            </div>

            <div className="unitsContainer__close-button">
              <button onClick={this.props.closeCallback}>
                <img src={closeImage} alt="" />
              </button>
            </div>
          </div>
          <div className="unitsContainer__content__no-units">
            <h4>:( No tenemos unidades disponibles de esta versión.</h4>
            <p>Llamános al +54 261 4439300 para más información.</p>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default UnitsContainer;
