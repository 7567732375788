import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import FormContainer from 'components/organisms/FormContainer';
import FormFieldset from 'components/molecules/FormFieldset';
import FormTextField from 'components/molecules/FormTextField';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
import NoPlan from 'containers/PlanBuy/NoPlan';
import PlanTable from 'components/organisms/PlanTable';
import { fetchPlanWithDetail, initSubscription } from 'actions/plans';
import { currency } from 'helpers/format';
import { getHero as getVehicleImage } from 'helpers/vehicle';

import './PlanInstallmentsSimulator.scss';

class PlanInstallmentsSimulator extends Component {
  state = {
    increase: 0,
    installments: []
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.calculate = this.calculate.bind(this);
    this.reattempt = this.reattempt.bind(this);
  }

  handleChange(e) {
    this.setState({ increase: Math.abs(parseFloat(e.target.value || 0) || 0) });
  }

  reattempt() {
    scrollToComponent(this.simulatorSection, {
      align: 'top'
    });
  }

  calculate() {
    const plan = Object.assign(
      Object.create(Object.getPrototypeOf(this.props.plan)),
      this.props.plan
    );

    plan.vehicle_price = (
      plan.vehicle_price *
      (1 + this.state.increase / 100)
    ).toFixed(2);

    this.setState({
      plan,
      installments: plan.calculateInstallments(this.state.increase)
    });

    scrollToComponent(this.installmentsSection, {
      align: 'top'
    });
  }

  componentDidMount() {
    const { plan } = this.props;

    if (!plan || plan.id !== this.props.match.params.id) {
      this.props.fetchPlan(this.props.match.params.id);
    }
  }

  render() {
    const { loading } = this.props;
    const { increase } = this.state;

    const plan = this.state.plan ? this.state.plan : this.props.plan;
    const installments = this.state.installments;

    if (loading || plan === undefined) {
      return <Loader />;
    }

    if (plan === null) {
      return this.renderNoPlan();
    }

    const heroImage = getVehicleImage(plan.family);

    return (
      <div>
        <MobileNavigation />
        <Header />

        <div className="planInstallmentsSimulator container">
          <div className="planInstallmentsSimulator__hero">
            <div className="planInstallmentsSimulator__hero__data">
              <h4>Nissan Plan de Ahorro | {plan.name}</h4>

              <h1>Simular aumento de cuotas</h1>
              <br />

              <NavLink to={plan.detail_link}>
                <Button back arrow color="gray">
                  Volver al plan
                </Button>
              </NavLink>
            </div>
            <div className="planInstallmentsSimulator__hero__image">
              <img src={heroImage} alt={plan.family} />
            </div>
          </div>
          <hr />
          <div className="planInstallmentsSimulator__simulate">
            <div className="planInstallmentsSimulator__simulateHelp">
              <h2>Te ayudamos a simular como puede aumentar tu cuota</h2>
              <p>
                El valor móvil de los vehículos varía con el tiempo y esto
                afecta al valor de la cuota, desde Yacopini Nissan te permitimos
                ver como cambiarían tus cuotas en el tiempo para que puedas
                tomar la desición más tranquilamente.
              </p>

              <FormContainer color="white">
                <h4
                  className="form-label"
                  ref={section => (this.simulatorSection = section)}
                >
                  Ingresá el porcentaje de aumento a simular
                </h4>
                <FormFieldset>
                  <div className="form-inputs">
                    <FormTextField
                      name="percentage"
                      inline
                      size="medium"
                      placeholder="Ej: 10%"
                      value={increase}
                      onChange={this.handleChange}
                    />
                    <Button inline onClick={this.calculate}>
                      Calcular aumento
                    </Button>
                  </div>
                </FormFieldset>
              </FormContainer>
            </div>
          </div>
          <hr />
          <div className="planInstallmentsSimulator__buy">
            <div className="planInstallmentsSimulator__buyInfoMargin" />

            <div className="planInstallmentsSimulator__buyInfo">
              <h5>Valor Móvil Financiado con {increase}% de aumento </h5>
              <h1>{currency(plan.vehicle_price)}</h1>
              <h6>VALOR ACTUAL: {currency(this.props.plan.vehicle_price)}</h6>
            </div>
            <div className="planInstallmentsSimulator__buyInfoMargin" />
            <div className="planInstallmentsSimulator__buyInfoMargin" />
            <div
              className="planInstallmentsSimulator__installmentsTable"
              ref={section => (this.installmentsSection = section)}
            >
              <PlanTable>
                {installments.map(installment => (
                  <div
                    className="priceTable__row"
                    key={installment.installment}
                  >
                    <div className="priceTable__item">
                      Cuota {installment.installment}
                    </div>
                    <div className="priceTable__price">
                      {currency(installment.amount)}
                    </div>
                  </div>
                ))}
              </PlanTable>
            </div>

            <hr />
            <div className="planInstallmentsSimulator__buyCTA">
              <NavLink to={`/planes-de-ahorro-detalle`}>
                <Button back arrow color="gray">
                  Volver al plan
                </Button>
              </NavLink>

              <NavLink to={plan.simulate_link}>
                <Button onClick={this.reattempt}>
                  Calcular otro porcentaje
                </Button>
              </NavLink>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }

  renderNoPlan() {
    return <NoPlan />;
  }
}

const mapStateToProps = state => {
  return {
    plan: state.plans.plan,
    loading: state.plans.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlan: id => {
      dispatch(initSubscription());
      dispatch(fetchPlanWithDetail(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanInstallmentsSimulator);
