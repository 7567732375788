import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import PromotionsInfo from 'components/molecules/PromotionsInfo';
import Carousel from 'components/organisms/Carousel';
import VehicleCard from 'components/molecules/VehicleCard';

import { fetchOffers } from 'actions/stock';

import { url } from 'helpers/format';

import './Home.scss';

class Home extends Component {
  constructor(props) {
    super(props);

    props.fetchOffers();
  }

  render() {
    const { loading, heros } = this.props;

    const offers = this.getBestOffers();

    return (
      <div>
        <MobileNavigation />
        <Header />
        <Carousel items={heros}>
          {/*<CarouselSlide
            type="light"
            src={slideImageMurano}
            title="Nissan Murano"
            subtitle="Tan única como lo que hacés"
            buttonText="Conoce todos los detalles"
          />*/}
        </Carousel>
        <div className="pageContent">
          <div className="container">
            {!loading && offers && (
              <div className="highlights">
                <h2 className="sectionTitle">Destacados</h2>
                <div className="vehicleList">
                  {offers.map((vehicle, i) => (
                    <VehicleCard
                      vehicle={vehicle}
                      link={`/reservar/${vehicle.id}/${url(vehicle.name)}`}
                      offer={true}
                      actionLabel="Reservar online"
                      key={vehicle.id}
                    />
                  ))}
                  {offers.length === 0 && (
                    <div className="container">
                      <div className="no-highlights">
                        Estamos preparando las mejores ofertas para vos.
                      </div>
                    </div>
                  )}
                </div>

                <Link to="/oportunidades">Ver todas las oportunidades</Link>
              </div>
            )}
          </div>
        </div>
        <PromotionsInfo />
        <Footer />
      </div>
    );
  }

  getBestOffers() {
    if (!this.props.offers) {
      return [];
    }

    if (this.props.offers.length <= 3) {
      return this.props.offers;
    }

    const offers = this.props.offers.sort((a, b) => {
      return parseFloat(b.special_discount) - parseFloat(a.special_discount);
    });

    const familyHash = {};

    return offers.reduce((offers, offer) => {
      if (offers.length === 3) {
        return offers;
      }

      if (!familyHash[offer.family_id]) {
        familyHash[offer.family_id] = true;
        offers.push(offer);
      }

      return offers;
    }, []);
  }
}

const mapStateToProps = state => {
  return {
    loading: state.stock.loading,
    offers: state.stock.offers,
    heros: state.parameters.heros,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOffers: () => {
      dispatch(fetchOffers());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
