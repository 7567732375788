import './RadioButton.scss';

const RadioButton = ({ status, image, name, value, ...etc }) => (
  <input
    className={
      'radioButton ' +
      (status ? 'radioButton-' + status + ' ' : '') +
      (image ? 'radioButton-image' : '')
    }
    {...etc}
    type="radio"
    name={name}
  />
);

export default RadioButton;
