import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import FormTextField from 'components/molecules/FormTextField';
import Button from 'components/atoms/Button';
//import ColorSelector from 'components/molecules/ColorSelector';
import Loader from 'components/atoms/Loader';
import PriceTable from 'components/organisms/PriceTable';
import {
  fetchStockItem,
  initPromotionCode,
  checkPromotionCode,
  discoverPromotionCode
} from 'actions/stock';
import { getHero as getVehicleImage, calculatePrice } from 'helpers/vehicle';
import { currency } from 'helpers/format';
import { url } from 'helpers/format';
import specialPriceImage from 'images/precios-justos.png';

import './UnitDetail.scss';

class UnitDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promotionCode: '',
      showPromotionCodeForm: false,
      timeout: null
    };

    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.applyAvailablePromotion = this.applyAvailablePromotion.bind(this);
  }

  applyAvailablePromotion() {
    this.props.checkPromotionCode({
      code: this.props.availablePromotion.code,
      family: this.props.vehicle.family,
      chassis: this.props.vehicle.chasis
    });
  }

  componentDidMount() {
    this.props.init();
    this.props.fetchStockItem(this.props.match.params.model_id);
  }

  handleCodeChange(e) {
    const promotionCode = e.target.value;

    const timeout = setTimeout(() => {
      this.props.checkPromotionCode({
        code: promotionCode,
        family: this.props.vehicle.family,
        chassis: this.props.vehicle.chasis
      });
    }, 500);

    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    this.setState({
      promotionCode,
      timeout
    });
  }

  render() {
    const {
      loading,
      vehicle,
      promotion,
      checkingPromotion,
      availablePromotion
    } = this.props;

    const { promotionCode, showPromotionCodeForm } = this.state;

    const heroImage = vehicle ? getVehicleImage(vehicle.family) : null;

    return (
      <div>
        <MobileNavigation />
        <Header />
        {loading && (
          <div className="unitDetail container">
            <div className="unitDetail__hero">
              <div className="unitDetail__hero__data">
                <Loader />
              </div>
            </div>
          </div>
        )}

        {vehicle === false && (
          <div className="unitDetail container">
            <div className="unitDetail__hero">
              <div className="unitDetail__hero__data">
                <h1>Unidad inexistente</h1>
                <p>Esta unidad no está disponible o fue vendida.</p>
              </div>
            </div>
          </div>
        )}

        {!loading && vehicle && (
          <div className="unitDetail container">
            <div className="unitDetail__hero">
              <div className="unitDetail__hero__data">
                <h1>{vehicle.name}</h1>
                <p>
                  Número de chasis: {vehicle.chasis}
                  <br />
                  {vehicle.doors}. Color: {vehicle.color}.
                </p>

                <p>{vehicle.description}</p>
                {/*<ColorSelector activeColorName={vehicle.color}>
                    <ColorItem color="#3323AA" status="active" />
                    <ColorItem color="#FF5577" />
                    <ColorItem color="#3323AA" />
                    <ColorItem color="#FF5577" />
                    <ColorItem color="#FF5577" />
                    <ColorItem color="#3323AA" />
                    <ColorItem color="#FF5577" />
                    <ColorItem color="#FF5577" />
                    <ColorItem color="#3323AA" />
                  <ColorItem color="#FF5577" />
                  </ColorSelector>*/}
                {/*<Button arrow color="white">
                Ver caracteristicas
              </Button>*/}
              </div>
              <div className="unitDetail__hero__image">
                <img src={heroImage} alt="Kicks" />
              </div>
            </div>
            <div className="unitDetail__buy">
              <div className="unitDetail__buyHelp">
                <h2>Reserva de vehículo</h2>
                <p>
                  <strong>
                    Reservar online en Yacopini Nissan es muy fácil.
                  </strong>
                </p>
                <p>
                  Si estas seguro de que esta es la unidad que queres comprar,
                  hacé click en el boton de "Reservar esta unidad".
                </p>
                <p>
                  {' '}
                  Nos dejas tus datos y pagás una seña de {currency(this.props.bookingPrice)} pesos con tu
                  tarjeta de crédito. Con Visa podes pagar hasta en 3 cuotas sin
                  interés.{' '}
                </p>
                <p>
                  Luego tenes 48 horas para pasar a completar el trámite por
                  nuestras oficinas.
                </p>
                <p>
                  El pago es a través de nuestros medios de pago seguro, y si te
                  arrepentis dentro de las 48 horas te devolvemos el 100% de la
                  seña.
                </p>
                <NavLink to="/contacto">
                  <Button arrow>Dejanos tus datos</Button>
                </NavLink>
              </div>
              <div className="unitDetail__buyInfo">
                <h5>
                  Precio final{' '}
                  {parseFloat(vehicle.special_discount) ||
                  parseFloat(vehicle.web_discount)
                    ? 'bonificado'
                    : ''}
                </h5>
                <h1>{currency(calculatePrice(vehicle, promotion))}</h1>

                {vehicle.govPrice && (
                  <img src={specialPriceImage} width="auto" height="100" alt="Precios justos" style={{ marginTop: '2em'}} />
                )}

                <PriceTable vehicle={vehicle} promotion={promotion}>
                  {showPromotionCodeForm && !promotion && (
                    <div className="priceTable__row">
                      <div className="priceTable__item">
                        Código de descuento
                      </div>
                      <div className="priceTable__price">
                        <FormTextField
                          label=""
                          size="medium"
                          placeholder="Código"
                          onChange={this.handleCodeChange}
                          value={promotionCode}
                          readOnly={checkingPromotion}
                        />
                        {promotion === null && 'Código inexistente o vencido.'}
                      </div>
                    </div>
                  )}

                  {!showPromotionCodeForm && !promotion && (
                    <div className="priceTable__row">
                      <div className="priceTable__item">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ showPromotionCodeForm: true })
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          Tengo un código de descuento
                        </a>
                      </div>
                    </div>
                  )}
                  {!promotion &&
                    availablePromotion !== null &&
                    availablePromotion !== undefined && (
                      <div className="priceTable__row">
                        <div className="priceTable__item">
                          <strong>
                            Descuento especial {availablePromotion.code}{' '}
                            disponible
                          </strong>
                        </div>
                        <div className="priceTable__price">
                          <Button full onClick={this.applyAvailablePromotion}>
                            Agregar
                          </Button>
                        </div>
                      </div>
                    )}
                </PriceTable>

                <NavLink to={`/reservar/${vehicle.id}/${url(vehicle.name)}`}>
                  <Button arrow>Reservar esta unidad</Button>
                </NavLink>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }

  componentDidUpdate() {
    if (this.props.vehicle && this.props.availablePromotion === undefined) {
      this.props.discoverPromotion({
        family: this.props.vehicle.family,
        chassis: this.props.vehicle.chasis
      });
    }
  }
}

const mapStateToProps = state => {
  return {
    loading: state.stock.loading,
    vehicle: state.stock.item,
    promotion: state.stock.promotion,
    availablePromotion: state.stock.availablePromotion,
    checkingPromotion: state.stock.checkingPromotion,
    bookingPrice: state.parameters.booking_price || 200000,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchStockItem: id => {
      dispatch(fetchStockItem(id));
    },
    init: () => {
      dispatch(initPromotionCode());
    },
    checkPromotionCode: data => {
      dispatch(checkPromotionCode(data));
    },
    discoverPromotion: data => {
      dispatch(discoverPromotionCode(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetail);
