import { Component } from 'react';
import Button from 'components/atoms/Button';
import './CarouselSlide.scss';

class CarouselSlide extends Component {
  render() {
    var type = this.props.type || 'light';
    var src = this.props.src;
    var title = this.props.title;
    var link = this.props.link;
    const onClick = this.props.onClick;
    var target = this.props.target || '_self';
    let text;
    if (this.props.text) {
      text = (
        <div className="carouselSlide__text">
          <div className="carouselSlide__text__content">{this.props.text}</div>
        </div>
      );
    }

    var subtitle = this.props.subtitle;
    var status = this.props.status || 'inactive';
    var buttonText = this.props.buttonText || 'Conocelo';
    var image = {
      backgroundImage: 'url(' + src + ')'
    };

    let linkItem;
    if (link) {
      linkItem = (
        <Button arrow="true" link={link} onClick={onClick} target={target}>
          {buttonText}
        </Button>
      );
    } else {
      linkItem = (
        <Button arrow="true" onClick={onClick}>
          {buttonText}
        </Button>
      );
    }

    return (
      <div
        className={
          'carouselSlide carouselSlide-' + type + ' carouselSlide-' + status
        }
      >
        <div className="carouselSlide__image" style={image} />
        <div className="container">
          <div className="col">
            <h4 className="carouselSlide__subtitle">{subtitle}</h4>
            <h1 className="carouselSlide__title">{title}</h1>
            {text}
            <br />
            <div className="carouselSlide__action">{linkItem}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CarouselSlide;
