import buttonArrow from 'images/buttonarrow.svg';
import './CarouselNavButton.scss';

function CarouselNavButton({ type = 'left', ...etc }) {
  return (
    <div className={'carouselNavButton carouselNavButton-' + type} {...etc}>
      <img src={buttonArrow} alt="Siguiente" />
    </div>
  );
}

export default CarouselNavButton;
