import './PromotionsInfo.scss';

const PromotionsInfo = () => {
  return false;

  return (
    <div className="promotionInfo">
      <div className="container">
        <div className="title">
          <h4>Bases y condiciones</h4>
        </div>
        <div className="promotionInfo__item">
          <strong>Promotions title</strong>{' '}
          <a href="#" target="_blank">
            Descarga las bases y condiciones
          </a>
          .
        </div>
        <div className="promotionInfo__item">
          <strong>Promotions title</strong>{' '}
          <a href="#" target="_blank">
            Descarga las bases y condiciones
          </a>
          .
        </div>
      </div>
    </div>
  );
};

export default PromotionsInfo;
