import { Component } from 'react';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';
import Message from 'components/atoms/Message';
import Wizard from 'components/molecules/Wizard';
import WizardStep from 'components/atoms/WizardStep';
import FormContainer from 'components/organisms/FormContainer';
import FormFieldset from 'components/molecules/FormFieldset';
import FormTextField from 'components/molecules/FormTextField';
import FormSelect from 'components/molecules/FormSelect';
import FormCheckbox from 'components/molecules/FormCheckbox';
import FormActions from 'components/molecules/FormActions';
import Loader from 'components/atoms/Loader';
import NoPlan from './NoPlan';
import BenefitsBox from 'components/organisms/PlanCoupon/ValidateAndDisplay';
import { fetchPlan, subscribe, initSubscription } from 'actions/plans';
import { getHero as getVehicleImage } from 'helpers/vehicle';
import { currency } from 'helpers/format';

import './PlanBuy.scss';

class PlanBuy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      data: {
        subscription: {
          name: '',
          lastname: '',
          dni: '',
          email: '',
          marital_status: 'single',
          birthdate: '',
          address: '',
          telephone: '',
          payed_installments: props.enableBooking ? 0 : 1,
          code: props.match.params.code || '',
          payment_amount: 0
        },
        payment: {
          accept: false
        }
      },
      coupon: null,
      processing: false
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleCodeValidate = this.handleCodeValidate.bind(this);
  }

  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.id);
  }

  handleCodeValidate(code, coupon) {
    const newState = { ...this.state };

    if (coupon.plan !== this.props.plan.id) {
      window.location.replace(
        `/planes-de-ahorro/suscribirse/${coupon.plan}/con-beneficios/${code}`
      );
    }

    newState.data.subscription.code = code;
    newState.coupon = coupon;

    this.setState(newState);
  }

  handleOnChange(event, type, name) {
    const newState = Object.assign({}, this.state);
    const fieldName = name || event.target.name;

    let value;

    if (typeof event !== 'object') {
      value = event;
    } else if (event.target.selectedIndex) {
      value = event.target.options[event.target.selectedIndex].value;
    } else {
      value = event.target.value;
    }

    newState.data[type][fieldName] = value;

    this.setState(newState);
  }

  canContinue(step) {
    const subscription = this.state.data.subscription;
    const payment = this.state.data.payment;

    if (step === 1) {
      return (
        subscription.name &&
        subscription.lastname &&
        subscription.email &&
        subscription.telephone &&
        subscription.dni &&
        subscription.address &&
        subscription.birthdate
      );
    }

    if (step === 2) {
      return payment.accept;
    }

    return false;
  }

  submit(e) {
    this.setState({ processing: true, step: 3 });

    let payment_amount = this.getPaymentAmount();
    let payed_installments = this.state.data.subscription.payed_installments;

    if (
      payed_installments === 0 &&
      payment_amount === this.props.plan.initial_installment
    ) {
      payed_installments = 1;
    }

    if (
      payed_installments === 0 &&
      payment_amount === this.props.plan.first_and_second_installments
    ) {
      payed_installments = 2;
    }

    if (
      payed_installments === 0 &&
      payment_amount === this.props.plan.first_three_installments
    ) {
      payed_installments = 3;
    }

    this.props.sendPurchaseRequest({
      subscription: {
        ...this.state.data.subscription,
        payment_amount,
        plan_first_installment: this.props.plan.initial_installment,
        plan_first_and_second_installment: this.props.plan
          .first_and_second_installments,
        payed_installments
      },
      plan: this.props.plan
    });
  }

  getPaymentAmount() {
    if (this.state.data.subscription.payment_amount > 0) {
      return this.state.data.subscription.payment_amount;
    }

    switch (parseInt(this.state.data.subscription.payed_installments, 10)) {
      case 1:
        return this.props.plan.initial_installment;
      case 2:
        return this.props.plan.first_and_second_installments;
      case 3:
        return this.props.plan.first_three_installments;
      default:
        return 0;
    }
  }

  goToStep(step) {
    scrollToComponent(this.Header, { align: 'top' });

    this.setState({ step });
  }

  render() {
    const {
      plan,
      loading,
      purchasing,
      purchaseSuccess,
      purchaseError,
      transaction,
      enableBooking
    } = this.props;

    const {
      step,
      data: { subscription, payment }
    } = this.state;

    if (loading || plan === undefined) {
      return <Loader />;
    }

    if (plan === null) {
      return this.renderNoPlan();
    }

    const maritalStatus = [
      { name: 'Casado', value: 'married' },
      { name: 'Soltero/a', value: 'single' },
      { name: 'Divorciado/a', value: 'divorced' },
      { name: 'Viudo/a', value: 'widowed' }
    ];

    const installmentsPayment = [
      {
        name: `Primera cuota (${currency(plan.initial_installment)})`,
        value: 1
      },
      {
        name: `Primera y segunda cuota (${currency(
          plan.first_and_second_installments
        )})`,
        value: 2
      },
      {
        name: `Primeras tres cuotas (${currency(
          plan.first_three_installments
        )})`,
        value: 3
      }
    ];

    const paymentAmount = this.getPaymentAmount();

    const processing = purchasing;

    return (
      <div className="planBuy">
        <MobileNavigation />
        <Header />
        <div className="unitDetail container">
          <div className="unitDetail__hero">
            <div className="unitDetail__hero__data">
              {enableBooking && <h4>Señar Plan de Ahorro</h4>}
              {!enableBooking && (
                <h4>Estas suscribiendote al Plan de Ahorro</h4>
              )}
              <h1>{plan.name}</h1>
              <p>
                Modelo: <strong>{plan.model}</strong>
                <br />
                Operatoria: <strong>{plan.integration}</strong>
                <br />
                Meses: <strong>{plan.installments}</strong>
                <br />
                Precio / Valor Móvil:{' '}
                <strong>{currency(plan.vehicle_price)}</strong>
                <br />
                Cuota de adhesión:{' '}
                <strong>{currency(plan.initial_installment)}</strong>
                <br />
                {subscription.code && (
                  <>
                    Cuota de adhesión: <strong>{subscription.code}</strong>
                  </>
                )}
              </p>
              <BenefitsBox
                code={subscription.code}
                onValidate={this.handleCodeValidate}
              />
            </div>
            <div className="unitDetail__hero__image">
              <img src={getVehicleImage(plan.family)} alt={plan.model} />
            </div>
          </div>

          {purchaseSuccess === true && (
            <div className="unitDetail__buy">
              <div className="unitDetail__buyHelp">
                <h2>Ya casi terminamos.</h2>
                <p>
                  <strong>
                    Recibimos tu pedido de subscripción al plan {plan.name}.
                  </strong>
                </p>
                <p>
                  Una vez hecho el pago {enableBooking ? 'completo' : ''},
                  acercate a nuestro concesionario para terminar de concretar la
                  subscripción. Te esperamos en San Martín Sur 719, Godoy Cruz,
                  Mendoza.
                </p>
                <p>Cualquier duda que tengas llamanos al +54 261 4439300.</p>
              </div>
            </div>
          )}

          <div className="unitDetail__buy">
            <div className="unitDetail__buy__step" id="personal-info">
              <Wizard>
                <WizardStep
                  status={step === 1 ? 'active' : 'complete'}
                  stepName="Tus Datos"
                  stepNumber="1"
                />
                <WizardStep
                  status={step === 2 ? 'active' : 'complete'}
                  stepName="Cuotas y confirmación"
                  stepNumber="2"
                />
                <WizardStep
                  status={step === 3 ? 'active' : 'complete'}
                  stepName="Pago de tu subscripción"
                  stepNumber="3"
                />
              </Wizard>

              <div
                ref={section => {
                  this.Header = section;
                }}
              />

              {step === 1 && (
                <FormContainer color="white">
                  <h3>Completá tus datos</h3>

                  <FormFieldset>
                    <FormTextField
                      label="Nombre"
                      name="name"
                      value={subscription.name}
                      onChange={event =>
                        this.handleOnChange(event, 'subscription')
                      }
                    />
                    <FormTextField
                      label="Apellido"
                      name="lastname"
                      value={subscription.lastname}
                      onChange={event =>
                        this.handleOnChange(event, 'subscription')
                      }
                    />
                    <FormTextField
                      label="DNI"
                      size="small"
                      name="dni"
                      inline
                      value={subscription.dni}
                      onChange={event =>
                        this.handleOnChange(event, 'subscription')
                      }
                    />
                    <FormTextField
                      label="Fecha de nacimiento"
                      placeholder="DD/MM/AAAA"
                      size="medium"
                      inline
                      name="birthdate"
                      type="date"
                      value={subscription.birthdate}
                      onChange={event =>
                        this.handleOnChange(event, 'subscription')
                      }
                    />

                    <FormSelect
                      label="Estado civil"
                      size="medium"
                      name="marital_status"
                      value={subscription.marital_status}
                      onChange={event =>
                        this.handleOnChange(event, 'subscription')
                      }
                      values={maritalStatus}
                    />
                  </FormFieldset>
                  <FormFieldset>
                    <FormTextField
                      label="Dirección"
                      name="address"
                      value={subscription.address}
                      onChange={event =>
                        this.handleOnChange(event, 'subscription')
                      }
                    />
                    <FormTextField
                      label="Email"
                      name="email"
                      value={subscription.email}
                      onChange={event =>
                        this.handleOnChange(event, 'subscription')
                      }
                    />
                    <FormTextField
                      label="Teléfono de contacto"
                      size="medium"
                      name="telephone"
                      value={subscription.telephone}
                      onChange={event =>
                        this.handleOnChange(event, 'subscription')
                      }
                    />
                  </FormFieldset>
                  <FormActions>
                    <Button
                      arrow
                      disabled={!this.canContinue(1)}
                      onClick={() => this.goToStep(2)}
                    >
                      Continuar
                    </Button>
                  </FormActions>
                </FormContainer>
              )}

              {step === 2 && (
                <FormContainer color="white">
                  {enableBooking && <h3>Ingresá el importe de la seña</h3>}
                  {!enableBooking && <h3>Elegí como pagar la reserva</h3>}

                  <FormFieldset>
                    {enableBooking && (
                      <FormTextField
                        label="Ingresá el importe de tu seña"
                        size="medium"
                        type="number"
                        name="payment_amount"
                        value={subscription.payment_amount}
                        helpText={`Ingresá un importe de hasta ${currency(
                          plan.initial_installment
                        )} y ${currency(
                          plan.first_and_second_installments
                        )} para la cuota inicial, o inicial y primer cuota.`}
                        onChange={event =>
                          this.handleOnChange(event, 'subscription')
                        }
                        max={plan.first_and_second_installments}
                        min={2000}
                        error={
                          parseFloat(subscription.payment_amount).toFixed(2) >=
                            plan.first_three_installments ||
                          parseFloat(subscription.payment_amount).toFixed(2) >=
                            plan.first_and_second_installments ||
                          parseFloat(subscription.payment_amount).toFixed(2) ===
                            plan.initial_installment
                            ? 'El importe corresponde al importe de una o más cuotas, por lo que se considerará como subscripción y no seña.'
                            : null
                        }
                      />
                    )}

                    {!enableBooking && (
                      <FormSelect
                        label="Cantidad de cuotas de tu plan a pagar"
                        size="medium"
                        name="payed_installments"
                        onChange={event =>
                          this.handleOnChange(event, 'subscription')
                        }
                        values={installmentsPayment}
                        value={subscription.payed_installments}
                      />
                    )}
                    <FormCheckbox
                      size="full"
                      name="accept"
                      value={payment.accept}
                      onChange={event =>
                        this.handleOnChange(
                          !payment.accept,
                          'payment',
                          'accept'
                        )
                      }
                    >
                      Acepto los términos y condiciones
                    </FormCheckbox>
                  </FormFieldset>

                  <FormActions>
                    <Button
                      arrow
                      disabled={!this.canContinue(2)}
                      onClick={() => this.submit()}
                    >
                      Continuar
                    </Button>
                    <br />
                    <br />
                    <Button
                      arrow
                      back
                      color="white"
                      onClick={() => this.goToStep(1)}
                    >
                      Cambiar datos personales
                    </Button>
                  </FormActions>

                  <Message
                    title="Subscribite con tranquilidad"
                    type="info"
                    detail={`Una vez completo el formulario y realizado el pago ${
                      enableBooking ? 'total' : ''
                    }, tenés
                  48 horas para comunicarte con nuestro concesionario y finalizar
                  los trámites. Si no estás conforme o no se concreta, se te
                  reintegrará el importe completo.`}
                  />
                </FormContainer>
              )}

              {step === 3 && processing && <Loader />}

              {step === 3 && purchaseSuccess && (
                <FormContainer color="white">
                  <h3>Confirmá tu suscripción</h3>
                  {enableBooking && (
                    <p>
                      {subscription.name}, estás a punto de realizar un pago
                      parcial para un Nissan Plan, realizando un{' '}
                      <strong>
                        pago con tarjeta de crédito o débito por un importe de{' '}
                        {currency(paymentAmount)}.
                      </strong>{' '}
                      <br />
                      Si no pagaste el importe total, recibirás un e-mail con un
                      acceso directo para que completes tu subscripción.
                    </p>
                  )}
                  {!enableBooking && (
                    <p>
                      {subscription.name}, estás a punto de suscribirte a Nissan
                      Plan, realizando un{' '}
                      <strong>
                        pago con tarjeta de crédito o débito por un importe de{' '}
                        {currency(paymentAmount)}.
                      </strong>{' '}
                      <br />
                      Una vez completo el formulario y realizado el pago, nos
                      estaremos contactando con vos para finalizar con el
                      proceso de subscripción.
                    </p>
                  )}

                  <iframe
                    title="Pago con LinkingPOS"
                    src={`https://linkingpos.com/external_payments/new?access_token=ZVwLKwS7hugny35TxvSewV9o&import=${paymentAmount}&client_ref=${transaction.code}`}
                    style={{
                      width: '100%',
                      height: '820px',
                      overflowY: 'hidden'
                    }}
                    scrolling="no"
                    frameBorder="0"
                  ></iframe>
                </FormContainer>
              )}

              {step === 3 && purchaseError && (
                <FormContainer color="white">
                  <h3>Error al solicitar la subscripción</h3>
                  <p>
                    Por favor intentalo nuevamente más tarde o revisá tus datos
                    personales.
                  </p>

                  <FormActions>
                    <Button
                      arrow
                      back
                      color="white"
                      onClick={() => this.goToStep(2)}
                    >
                      Reintentar
                    </Button>
                  </FormActions>
                </FormContainer>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  renderNoPlan() {
    return <NoPlan />;
  }
}

const mapStateToProps = state => {
  return {
    loading: state.plans.loading,
    purchasing: state.plans.purchasing,
    plan: state.plans.plan,
    purchaseError: state.plans.purchaseError,
    purchaseSuccess: state.plans.purchaseSuccess,
    transaction: state.plans.transaction
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlan: id => {
      dispatch(initSubscription());
      dispatch(fetchPlan(id));
    },
    sendPurchaseRequest: data => {
      dispatch(subscribe(data));
    },
    reset: () => {
      dispatch(initSubscription());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanBuy);
