import { Component } from 'react';
import Checkbox from 'components/atoms/Checkbox';
import './FormCheckbox.scss';

class FormCheckbox extends Component {
  render() {
    /* STATUS = "error", "disabled", "selected" */
    const { disabled, size, value, ...etc } = this.props;

    let status;

    if (disabled) {
      status = 'disabled';
    } else if (value) {
      status = 'selected';
    }

    return (
      <div
        className={
          'formCheckbox ' +
          (status ? 'formCheckbox-' + status + ' ' : '') +
          'formCheckbox ' +
          (size ? 'formCheckbox-' + size + ' ' : '')
        }
      >
        <Checkbox {...etc} />
        <div className="check" />
        <div className="label">{this.props.children}</div>
      </div>
    );
  }
}

export default FormCheckbox;
