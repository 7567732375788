import RadioButton from 'components/atoms/RadioButton';
import './FormRadioButton.scss';

export default ({
  name,
  value,
  selectedValue,
  disabled,
  children,
  onClick,
  image,
  ...etc
}) => {
  /* STATUS = "error", "disabled", "selected" */
  let status;

  if (disabled) {
    status = 'disabled';
  } else if (value === selectedValue) {
    status = 'selected';
  }

  return (
    <div
      className={
        'formRadioButton ' +
        (status ? 'formRadioButton-' + status + ' ' : '') +
        (image ? 'formRadioButton-image ' : '')
      }
      onClick={() => {
        if (onClick) onClick(selectedValue);
      }}
    >
      <RadioButton name={name} status={status} {...etc} />
      <div className="label">{children}</div>
    </div>
  );
};
