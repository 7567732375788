import { Component } from 'react';
import './Wizard.scss';

class Wizard extends Component {
  render() {
    return <div className="wizard">{this.props.children}</div>;
  }
}

export default Wizard;
