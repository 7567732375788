import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'components/atoms/Button';
import { getImage as getVehicleImage } from 'helpers/vehicle';
import { currency as displayPrice } from 'helpers/format';
import './PlanCard.scss';

class PlanCard extends Component {
  render() {
    const {
      size,
      plan,
      subscribeLink,
      actionLabel,
      detailLink,
      bookLink
    } = this.props;

    let planImage = getVehicleImage(plan.family);

    return (
      <div className={'planCard ' + (size ? 'planCard-' + size + ' ' : '')}>
        <div className="planCard__container">
          <div className="planCard__title">
            <h2>{plan.name}</h2>
          </div>

          <div className="planCard__price">
            <h5>Cuota de adhesión</h5>
            <h2>{displayPrice(plan.initial_installment)}*</h2>
          </div>

          <div className="planCard__priceDetail">
            <div className="planCard__listPrice">
              <div className="planCard__discount__item">
                Operatoria: <strong>{plan.integration}</strong>
              </div>
            </div>
            <div className="planCard__listPrice">
              <div className="planCard__discount__item">
                Meses: <strong>{plan.installments}</strong>
              </div>
            </div>
          </div>

          <div className="planCard__image">
            <img src={planImage} alt={plan.name} />
          </div>
          <div className="planCard__actions">
            <NavLink to={detailLink}>
              <Button arrow="true" color="gray" type="full">
                Ver Detalle del Plan
              </Button>
            </NavLink>
            <NavLink to={subscribeLink}>
              <Button arrow="true" type="full">
                {actionLabel || 'Suscribirse'}
              </Button>
            </NavLink>
            {bookLink && (
              <NavLink to={bookLink}>
                <Button arrow="true" type="full">
                  Señar plan
                </Button>
              </NavLink>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PlanCard;
