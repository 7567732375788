import './WizardStep.scss';

const Input = props => (
  <div
    className={
      'wizardStep ' + (props.status ? 'wizardStep-' + props.status + ' ' : '')
    }
  >
    <div className="wizardStep__content">
      <div className="wizardStep__number">{props.stepNumber}.</div>
      {props.stepName}
    </div>
  </div>
);

export default Input;
