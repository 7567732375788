import { Component } from 'react';
import './Button.scss';
import arrowWhite from 'images/buttonarrow_white.svg';
import arrowBlack from 'images/buttonarrow_black.svg';
import arrowRed from 'images/buttonarrow.svg';

class Button extends Component {
  render() {
    let {
      status,
      type,
      color,
      arrow,
      back,
      link,
      target,
      inline,
      ...etc
    } = this.props;

    target = target || '_self';

    let arrowItem;

    if (arrow) {
      if (!color) {
        arrowItem = (
          <div className="button__arrow">
            <img src={arrowWhite} alt="Ir" />
          </div>
        );
      } else {
        if (color === 'gray') {
          arrowItem = (
            <div className="button__arrow">
              <img src={arrowBlack} alt="Ir" />
            </div>
          );
        }
        if (color === 'white') {
          arrowItem = (
            <div className="button__arrow">
              <img src={arrowRed} alt="Ir" />
            </div>
          );
        }
      }
    }
    let buttonItem;
    if (link) {
      buttonItem = (
        <a
          href={link}
          target={target}
          className={
            'button ' +
            (status ? ' button-' + status : '') +
            (type ? ' button-' + type : '') +
            (color ? ' button-' + color : '') +
            (arrow ? ' button-arrow' : '') +
            (back ? ' button-back' : '')
          }
        >
          {this.props.children}
          {arrowItem}
        </a>
      );
    } else {
      buttonItem = (
        <button
          type={type === 'submit' ? 'submit' : 'button'}
          {...etc}
          className={
            'button ' +
            (status ? ' button-' + status : '') +
            (type ? ' button-' + type : '') +
            (color ? ' button-' + color : '') +
            (arrow ? ' button-arrow' : '') +
            (back ? ' button-back' : '')
          }
        >
          {this.props.children}
          {arrowItem}
        </button>
      );
    }

    return (
      <div
        className={
          'button-container ' + (inline ? ' button-container-inline' : '')
        }
      >
        {buttonItem}
      </div>
    );
  }
}

export default Button;
