const defaultState = {
  list: [],
  loading: false,
  family: null
};

export default function models(state = defaultState, action) {
  switch (action.type) {
    case 'REQUEST_FAMILY_LIST':
      return Object.assign({}, state, { loading: true });

    case 'RECEIVE_FAMILY_LIST':
      return Object.assign({}, state, { loading: false, list: action.list });

    case 'REQUEST_FAMILY':
      return Object.assign({}, state, { loading: true });

    case 'RECEIVE_FAMILY':
      return Object.assign({}, state, {
        loading: false,
        family: action.family
      });
    default:
      return state;
  }
}
