import { Component } from 'react';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import Carousel from 'components/organisms/Carousel';
import CarouselSlide from 'components/molecules/CarouselSlide';
import ContactForm from 'components/organisms/ContactForm';
import slideImageContact from 'images/contact.jpg';

import './Contact.scss';

class Contact extends Component {
  scrollToForm() {
    const top = document.getElementById('form').offsetHeight;

    window.scroll(0, top + 100);
  }

  render() {
    return (
      <div>
        <MobileNavigation />
        <Header />
        <Carousel noNav>
          <CarouselSlide
            type="light"
            src={slideImageContact}
            title="Av. San Martín Sur 719"
            text="Godoy Cruz, Mendoza, Argentina"
            subtitle="Visitános en"
            buttonText="Contactanos"
            onClick={() => this.scrollToForm()}
            target="_blank"
            rel="noopener noreferrer"
            status="active"
          />
        </Carousel>
        <div className="pageContent">
          <div className="container">
            <div className="contactLocations">
              <div className="contactLocations__location">
                <h3>Mendoza</h3>
                <p>
                  Av. San Martín Sur 719
                  <br />
                  Godoy Cruz, Mendoza, Argentina.
                  <br /> <br />
                  <a
                    href="https://goo.gl/maps/c2nVJQTBeDN2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver en Mapa
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="contactData">
              <div className="contactData__main">
                <h3>Contacto</h3>
                <p>Contactanos por teléfono:</p>
                <p>
                  <strong>Directo:</strong> +54 261 4222848 <br />
                  <strong>Call Center:</strong> +54 261 4439300 <br />
                  <strong>WhatsApp:</strong>{' '}
                  <a
                    href="https://wa.me/5492615336863"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +54 9 2615 33-6863
                  </a>{' '}
                  <br />
                  <strong>Recepción Ventas:</strong> +54 261 4429824 <br />
                  <strong>Servicio:</strong> +54 261 4429822
                </p>

                <p>
                  <a
                    href="https://www.facebook.com/YacopiniNissan/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                  <br />
                  <a
                    href="https://twitter.com/YacopiniNissan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                  <br />
                  <a
                    href="https://twitter.com/search?q=yacopini%20nissan&src=typd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                  <br />
                  <a
                    href="https://www.youtube.com/channel/UCaII_9QKsBYfnlUwhrQaE1g"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>
                </p>
              </div>
              <div className="contactData__hours">
                <h3>Horarios</h3>
                <p>
                  <strong>Ventas</strong>
                  <br />
                  Lunes a Viernes: 10:00 a 14:00 - 15:00 a 19:00
                  <br />
                  Sábados: 10:00 a 13:00
                </p>
                <p>
                  <strong>Administración</strong>
                  <br />
                  Lunes a Viernes: 10:00 a 14:00 - 15:00 a 19:00
                </p>
                <p>
                  <strong>Postventa</strong>
                  <br />
                  Lunes a Viernes: 9:00 a 14:00 - 15:00 a 19:00
                </p>
              </div>
              <div className="contactData__services">
                <h3>Servicios</h3>
                <div className="serviceItem">Venta de 0km</div>
                <div className="serviceItem">Postventa</div>
              </div>
            </div>
          </div>

          <div className="pageContent">
            <div className="container">
              <div className="contactLocations">
                <div className="contactLocations__location">
                  <h3>San Rafael</h3>
                  <p>
                    Av. Bartolomé Mitre 530
                    <br />
                    San Rafael, Mendoza , Argentina.
                    <br /> <br />
                    <a
                      href="https://goo.gl/maps/FkETu1WpF7ke81mv8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver en Mapa
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="contactData">
                <div className="contactData__main">
                  <h3>Contacto</h3>
                  <p>Contactanos por teléfono:</p>
                  <p>
                    <strong>Directo:</strong>: 260 4420261 / 260 4420238
                    <strong>WhatsApp:</strong>{' '}
                    <a
                      href="https://wa.me/5492615336863 "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +54 9 261 5336863
                    </a>{' '}
                  </p>
                </div>
                <div className="contactData__hours">
                  <h3>Horarios</h3>
                  <p>
                    Lunes a Viernes: 09:00 a 13:00 - 15:00 a 20:00
                    <br/>
                    Sábados: 09:00 a 13:00
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="form">
            <ContactForm />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
