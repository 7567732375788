import { Component } from 'react';
import Label from 'components/atoms/Label';
import './FormRadioGroup.scss';

class FormRadioGroup extends Component {
  render() {
    var status = this.props.status;
    /* STATUS = "error", "disabled" */
    var label = this.props.label;

    return (
      <div
        className={
          'formRadioGroup ' + (status ? 'formRadioGroup-' + status + ' ' : '')
        }
      >
        <Label>{label}</Label>
        {this.props.children}
      </div>
    );
  }
}

export default FormRadioGroup;
