import fetch from 'isomorphic-fetch';
import { API_URL, INTERNAL_API_URL } from 'helpers/apiURL';
import Plan from 'models/plan';

/* ----------------------- List ----------------------- */
export const requestList = () => ({ type: 'REQUEST_PLAN_LIST' });

export const fetchList = () => {
  return function(dispatch) {
    dispatch(requestList());

    return fetch(`${API_URL}/plan/find`)
      .then(response => response.json())
      .then(json => dispatch(receiveList(json)));
  };
};

export const receiveList = response => ({
  type: 'RECEIVE_PLAN_LIST',
  list: response.data.map(plan => new Plan(plan))
});

/* ----------------------- Fetch ----------------------- */
export const requestPlan = () => ({ type: 'REQUEST_PLAN' });

export const fetchPlan = id => {
  return function(dispatch) {
    dispatch(requestPlan());

    return fetch(`${API_URL}/plan/find?id=${id}`)
      .then(response => response.json())
      .then(json => dispatch(receivePlan(json)))
      .catch(e => {
        console.error(e);
        dispatch(receivePlan());
      });
  };
};

export const fetchPlanWithDetail = id => {
  return function(dispatch) {
    dispatch(requestPlan());

    return fetch(`${API_URL}/plan/detail?id=${id}`)
      .then(response => response.json())
      .then(json => dispatch(receivePlan(json)))
      .catch(e => {
        console.error(e);
        dispatch(receivePlan());
      });
  };
};

export const receivePlan = response => ({
  type: 'RECEIVE_PLAN',
  plan: response && response.data ? new Plan(response.data) : null
});

/* ------------------- Purchase ------------------- */
export const stockItemPurchaseRequested = () => ({
  type: 'REQUEST_SUBSCRIPTION'
});

export const subscribe = data => {
  return function(dispatch) {
    dispatch(stockItemPurchaseRequested());

    return fetch(`${INTERNAL_API_URL}/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then(response => response.json())
      .then(response => {
        if (!response || !response.metadata) {
          dispatch(
            stockItemPurchaseError(
              'Error desconocido. Por favor reintentar más tarde.'
            )
          );
        }

        if (response.metadata.success) {
          dispatch(stockItemPurchased(response.data));
        }

        if (response.metadata.success !== true) {
          dispatch(stockItemPurchaseError(response.metadata.message));
        }
      })
      .catch(() => dispatch(stockItemPurchaseError()));
  };
};

export const initSubscription = () => ({
  type: 'INIT_SUBSCRIPTION'
});

export const tryAgain = () => ({
  type: 'RETRY_SUBSCRIPTION'
});

export const stockItemPurchased = transaction => ({
  type: 'SUBSCRIPTION_SUCCESSFUL',
  payload: transaction
});

export const stockItemPurchaseError = error => {
  return {
    type: 'REQUEST_SUBSCRIPTION_ERROR',
    error: error
  };
};
