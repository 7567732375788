import { Component } from 'react';
import { connect } from 'react-redux';

import FormContainer from 'components/organisms/FormContainer';
import FormFieldset from 'components/molecules/FormFieldset';
import FormTextField from 'components/molecules/FormTextField';
import FormActions from 'components/molecules/FormActions';
import Message from 'components/atoms/Message';
import Loader from 'components/atoms/Loader';
import Button from 'components/atoms/Button';

import { sendPriceRequest } from 'actions/stock';

class PriceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        vehicle: props.family.name
      }
    };
  }

  handleOnChange(e) {
    const form = Object.assign({}, this.state.form, {
      [e.target.name]: e.target.value
    });

    this.setState(Object.assign({}, this.state, { form }));
  }

  canSubmit() {
    return (
      this.state.form.name &&
      this.state.form.lastname &&
      this.state.form.cellphone
    );
  }

  sendForm(e) {
    e.preventDefault();

    this.props.requestPrice(this.state.form);
  }

  render() {
    const canSubmit = this.canSubmit();

    const { loading, error } = this.props;

    return (
      <form onSubmit={this.sendForm.bind(this)}>
        <FormContainer arrow>
          <h3>Consultá nuestros precios</h3>
          <p>
            Completando este formulario podrás ver nuestros precios y stock, y
            reservar tu próximo Nissan online obteniendo beneficios exclusivos
            por realizarlo desde nuestra web:
          </p>
          {!error && !loading && (
            <div>
              <FormFieldset>
                <FormTextField
                  label="Nombre"
                  onChange={this.handleOnChange.bind(this)}
                  id="price-request-name"
                  name="name"
                />
                <FormTextField
                  label="Apellido"
                  onChange={this.handleOnChange.bind(this)}
                  name="lastname"
                />
                <FormTextField
                  label="Número de celular"
                  onChange={this.handleOnChange.bind(this)}
                  name="cellphone"
                />
                <FormTextField
                  label="Email"
                  onChange={this.handleOnChange.bind(this)}
                  name="email"
                  type="email"
                />
              </FormFieldset>
              <FormActions>
                <Button type="submit" disabled={!canSubmit}>
                  Enviar
                </Button>
              </FormActions>
            </div>
          )}

          {!canSubmit && (
            <Message
              type="info"
              title="Pedido de precios"
              detail="Por favor completá todos los campos."
            />
          )}

          {error && (
            <Message
              type="error"
              title="Pedido de precios"
              detail="Hubo un error realizando el pedido. Por favor intenta de nuevo más tarde."
            />
          )}

          {loading && <Loader />}
        </FormContainer>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.stock.requestingPrice,
    error: state.stock.priceRequestError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestPrice: data => {
      dispatch(sendPriceRequest(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceForm);
