import { Component } from 'react';
import bugsnag from '@bugsnag/js';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';
import Message from 'components/atoms/Message';
import Wizard from 'components/molecules/Wizard';
import WizardStep from 'components/atoms/WizardStep';
import FormContainer from 'components/organisms/FormContainer';
import FormFieldset from 'components/molecules/FormFieldset';
import FormTextField from 'components/molecules/FormTextField';
import FormSelect from 'components/molecules/FormSelect';
import FormRadioGroup from 'components/molecules/FormRadioGroup';
import FormRadioButton from 'components/molecules/FormRadioButton';
import FormCheckbox from 'components/molecules/FormCheckbox';
import FormActions from 'components/molecules/FormActions';
import Loader from 'components/atoms/Loader';
import PriceTable from 'components/organisms/PriceTable';
import visa from 'images/visa.svg';
import amex from 'images/amex.svg';
import mastercard from 'images/mastercard.svg';
import naranja from 'images/naranja.svg';
import { fetchStockItem, purchaseStockItem, initPurchase } from 'actions/stock';
import { getHero as getVehicleImage, calculatePrice } from 'helpers/vehicle';
import { currency } from 'helpers/format';

import './Buy.scss';

const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_KEY);

class Buy extends Component {
  decidir;

  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      data: {
        customer: {
          name: '',
          lastname: '',
          dni: '',
          email: '',
          marital_status: 'single',
          birthdate: '',
          address: '',
          telephone: ''
        },
        payment: {
          name: '',
          cc: '',
          cvv: '',
          dni: '',
          month: '',
          year: '',
          accept: false,
          installments: 1,
          card: ''
        }
      },
      processing: false
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.props.init();

    this.props.fetchStockItem(this.props.match.params.model_id);

    this.decidir = new window.Decidir('https://live.decidir.com/api/v2');

    try {
      this.decidir.setPublishableKey('2c81df6c572544728222de5487cfb0df');
      this.decidir.setTimeout(50000);
    } catch (e) {
      bugsnagClient.notify(e);
      console.warn(e);
    }
  }

  handleOnChange(event, type, name) {
    const newState = Object.assign({}, this.state);
    const fieldName = name || event.target.name;

    let value;

    if (typeof event !== 'object') {
      value = event;
    } else if (event.target.selectedIndex) {
      value = event.target.options[event.target.selectedIndex].value;
    } else {
      value = event.target.value;
    }

    newState.data[type][fieldName] = value;

    this.setState(newState);
  }

  canContinue(step) {
    const customer = this.state.data.customer;
    const payment = this.state.data.payment;

    if (step === 1) {
      return (
        customer.name &&
        customer.lastname &&
        customer.email &&
        customer.telephone &&
        customer.dni &&
        customer.address &&
        customer.birthdate
      );
    }

    if (step === 2) {
      return (
        payment.name &&
        payment.cc &&
        payment.cvv &&
        payment.month &&
        payment.year &&
        payment.dni &&
        payment.accept &&
        payment.card
      );
    }

    return false;
  }

  submitCardInformation(e) {
    const form = document.getElementById('checkoutForm');

    this.setState({ processing: true });

    this.decidir.createToken(form, (status, response) => {
      this.setState({ processing: false });

      if (status !== 200 && status !== 201) {
        //Manejo de error: Ver Respuesta de Error
        alert('Por favor verifique los datos de la tarjeta.');

        bugsnagClient.notify(response);
      } else {
        const vehicle = Object.assign(
          {
            fullprice: calculatePrice(this.props.vehicle, this.props.promotion)
          },
          this.props.vehicle
        );

        //Manejo de respuesta donde response = {token: "99ab0740-4ef9-4b38-bdf9-c4c963459b22"}
        this.props.sendPurchaseRequest(this.props.vehicle.id, {
          customer: this.state.data.customer,
          vehicle,
          promotion_code: this.props.promotion
            ? this.props.promotion.code
            : null,
          family: vehicle.family,
          card: {
            token: response.id,
            bin: response.bin,
            installments: this.state.data.payment.installments,
            card: this.state.data.payment.card
          }
        });
      }
    });
  }

  goToStep(step) {
    scrollToComponent(this.Header);

    this.setState({ step });
  }

  render() {
    const {
      vehicle,
      loading,
      purchasing,
      purchaseSuccess,
      purchaseError,
      promotion
    } = this.props;

    if (loading || vehicle === null) {
      return (
        <div>
          <MobileNavigation />
          <Header />
          <div className="unitDetail container">
            <div className="unitDetail__hero">
              <div className="unitDetail__hero__data">
                <Loader />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }

    if (vehicle === false) {
      return (
        <div>
          <MobileNavigation />
          <Header />
          <div className="unitDetail container">
            <div className="unitDetail__hero">
              <div className="unitDetail__hero__data">
                <h1>Unidad inexistente</h1>
                <p>Esta unidad no está disponible o fue vendida.</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }

    const heroImage = vehicle ? getVehicleImage(vehicle.family) : null;

    const {
      step,
      data: { customer, payment }
    } = this.state;

    const maritalStatus = [
      { name: 'Casado', value: 'married' },
      { name: 'Soltero/a', value: 'single' },
      { name: 'Divorciado/a', value: 'divorced' },
      { name: 'Viudo/a', value: 'widowed' }
    ];

    const processing = this.state.processing || purchasing;

    return (
      <div>
        <script src="https://live.decidir.com/static/v2.4/decidir.js" />
        <MobileNavigation />
        <Header />
        <div className="unitDetail container">
          <div className="unitDetail__hero">
            <div className="unitDetail__hero__data">
              <h1>{vehicle.name}</h1>
              <p>
                Número de chasis: {vehicle.chasis}
                <br />
                {vehicle.doors}. {vehicle.color}.
              </p>
            </div>
            <div className="unitDetail__hero__image">
              <img src={heroImage} alt="Kicks" />
            </div>
          </div>
          {purchaseSuccess === true && (
            <div className="unitDetail__buy">
              <div className="unitDetail__buyHelp">
                <h2>¡Felicitaciones!</h2>
                <p>
                  <strong>Reservaste tu próximo {vehicle.name}.</strong>
                </p>
                <p>
                  Dentr de las 48 horas para acercate a nuestro concesionario
                  para terminar de concretar la compra. Te esperamos en San
                  Martín Sur 719, Godoy Cruz, Mendoza.
                </p>
                <p>Cualquier duda que tengas llamanos al 810 666 1033.</p>
              </div>
            </div>
          )}

          {!purchaseSuccess && (
            <div className="unitDetail__buy">
              <div className="unitDetail__buy__step" id="personal-info">
                <Wizard
                  ref={section => {
                    this.Header = section;
                  }}
                >
                  <WizardStep
                    status={step === 1 ? 'active' : 'complete'}
                    stepName="Tus Datos"
                    stepNumber="1"
                  />
                  <WizardStep
                    status={step === 2 ? 'active' : 'complete'}
                    stepName="Información de pago"
                    stepNumber="2"
                  />
                  <WizardStep
                    status={step === 3 ? 'active' : 'complete'}
                    stepName="Confirmación y pago"
                    stepNumber="3"
                  />
                </Wizard>

                {step === 1 && (
                  <FormContainer color="white">
                    <h3>Completá tus datos</h3>

                    <FormFieldset>
                      <FormTextField
                        label="Nombre"
                        name="name"
                        value={customer.name}
                        onChange={event =>
                          this.handleOnChange(event, 'customer')
                        }
                      />
                      <FormTextField
                        label="Apellido"
                        name="lastname"
                        value={customer.lastname}
                        onChange={event =>
                          this.handleOnChange(event, 'customer')
                        }
                      />
                      <FormTextField
                        label="DNI"
                        size="small"
                        name="dni"
                        inline
                        value={customer.dni}
                        onChange={event =>
                          this.handleOnChange(event, 'customer')
                        }
                      />
                      <FormTextField
                        label="Fecha de nacimiento"
                        placeholder="DD/MM/AAAA"
                        size="medium"
                        type="date"
                        inline
                        name="birthdate"
                        value={customer.birthdate}
                        onChange={event =>
                          this.handleOnChange(event, 'customer')
                        }
                      />

                      <FormSelect
                        label="Estado civil"
                        size="medium"
                        name="marital_status"
                        value={customer.marital_status}
                        onChange={event =>
                          this.handleOnChange(event, 'customer')
                        }
                        values={maritalStatus}
                      />
                    </FormFieldset>
                    <FormFieldset>
                      <FormTextField
                        label="Dirección"
                        name="address"
                        value={customer.address}
                        onChange={event =>
                          this.handleOnChange(event, 'customer')
                        }
                      />
                      <FormTextField
                        label="Email"
                        name="email"
                        value={customer.email}
                        onChange={event =>
                          this.handleOnChange(event, 'customer')
                        }
                      />
                      <FormTextField
                        label="Teléfono de contacto"
                        size="medium"
                        name="telephone"
                        value={customer.telephone}
                        onChange={event =>
                          this.handleOnChange(event, 'customer')
                        }
                      />
                    </FormFieldset>
                    <FormActions>
                      <Button
                        arrow
                        disabled={!this.canContinue(1)}
                        onClick={() => this.goToStep(2)}
                      >
                        Continuar
                      </Button>
                    </FormActions>
                  </FormContainer>
                )}

                <FormContainer
                  color="white"
                  style={{ display: step === 2 ? 'block' : 'none' }}
                >
                  <h3>Elegí como pagar la seña*</h3>

                  <FormFieldset>
                    <FormRadioGroup label="Seleccioná tarjeta:">
                      <FormRadioButton
                        value={payment.card}
                        selectedValue="visa"
                        onClick={value =>
                          this.handleOnChange(value, 'payment', 'card')
                        }
                      >
                        <img src={visa} alt="Visa" />
                      </FormRadioButton>
                      <FormRadioButton
                        value={payment.card}
                        selectedValue="mastercard"
                        onClick={value =>
                          this.handleOnChange(value, 'payment', 'card')
                        }
                      >
                        <img src={mastercard} alt="MasterCard" />
                      </FormRadioButton>

                      <FormRadioButton
                        value={payment.card}
                        selectedValue="naranja"
                        onClick={value =>
                          this.handleOnChange(value, 'payment', 'card')
                        }
                      >
                        <img src={naranja} alt="Naranja" />
                      </FormRadioButton>

                      <FormRadioButton
                        value={payment.card}
                        selectedValue="amex"
                        onClick={value =>
                          this.handleOnChange(value, 'payment', 'card')
                        }
                      >
                        <img src={amex} alt="AmericanExpress" />
                      </FormRadioButton>
                    </FormRadioGroup>
                    {payment.card && (
                      <FormRadioGroup label={`Pagaré una seña de ${currency(this.props.bookingPrice)} pesos en:`}>
                        <FormRadioButton
                          value={payment.installments}
                          selectedValue={1}
                          onClick={value =>
                            this.handleOnChange(
                              value,
                              'payment',
                              'installments'
                            )
                          }
                        >
                          1 cuota
                        </FormRadioButton>
                        <FormRadioButton
                          value={payment.installments}
                          selectedValue={2}
                          onClick={value =>
                            this.handleOnChange(
                              value,
                              'payment',
                              'installments'
                            )
                          }
                        >
                          2 cuotas
                        </FormRadioButton>
                        <FormRadioButton
                          value={payment.installments}
                          selectedValue={3}
                          onClick={value =>
                            this.handleOnChange(
                              value,
                              'payment',
                              'installments'
                            )
                          }
                        >
                          3 cuotas
                        </FormRadioButton>
                      </FormRadioGroup>
                    )}
                  </FormFieldset>
                  <hr />
                  <form
                    onSubmit={this.submitCardInformation.bind(this)}
                    id="checkoutForm"
                  >
                    <FormFieldset>
                      <FormTextField
                        label="Nombre del titular"
                        data-decidir="card_holder_name"
                        autoComplete="off"
                        placeholder="Nombre y Apellido"
                        onChange={event =>
                          this.handleOnChange(event, 'payment', 'name')
                        }
                      />
                      <FormTextField
                        label="DNI"
                        data-decidir="card_holder_doc_number"
                        autoComplete="off"
                        maxLength="8"
                        size="medium"
                        placeholder="Sin puntos"
                        onChange={event =>
                          this.handleOnChange(event, 'payment', 'dni')
                        }
                      />

                      <FormTextField
                        label="Número de tarjeta"
                        placeholder="Ej: 1234567898765432"
                        data-decidir="card_number"
                        autoComplete="off"
                        maxLength="17"
                        onChange={event =>
                          this.handleOnChange(event, 'payment', 'cc')
                        }
                      />

                      <FormTextField
                        label="Mes de vencimiento"
                        data-decidir="card_expiration_month"
                        autoComplete="off"
                        maxLength="2"
                        size="small"
                        inline
                        placeholder="00"
                        onChange={event =>
                          this.handleOnChange(event, 'payment', 'month')
                        }
                      />

                      <FormTextField
                        label="Año de vencimiento"
                        data-decidir="card_expiration_year"
                        autoComplete="off"
                        maxLength="2"
                        size="small"
                        inline
                        placeholder="00"
                        onChange={event =>
                          this.handleOnChange(event, 'payment', 'year')
                        }
                      />

                      <FormTextField
                        label="Código de seguridad"
                        data-decidir="security_code"
                        autoComplete="off"
                        maxLength="4"
                        size="small"
                        placeholder="Ej: 123"
                        onChange={event =>
                          this.handleOnChange(event, 'payment', 'cvv')
                        }
                      />

                      <input
                        type="hidden"
                        data-decidir="card_holder_doc_type"
                        value="DNI"
                      />

                      <FormCheckbox
                        size="full"
                        name="accept"
                        value={payment.accept}
                        onChange={event =>
                          this.handleOnChange(
                            !payment.accept,
                            'payment',
                            'accept'
                          )
                        }
                      >
                        Acepto los términos y condiciones
                      </FormCheckbox>
                    </FormFieldset>

                    <FormActions>
                      <Button
                        arrow
                        disabled={!this.canContinue(2)}
                        onClick={() => this.goToStep(3)}
                      >
                        Continuar
                      </Button>
                      <br />
                      <br />
                      <Button
                        arrow
                        back
                        color="white"
                        onClick={() => this.goToStep(1)}
                      >
                        Cambiar datos personales
                      </Button>
                    </FormActions>
                  </form>

                  <Message
                    title="Reservá con tranquilidad"
                    type="info"
                    detail="Una vez completo el formulario y realizado el pago, tenés
                  48 horas para acercarte a nuestro concesionario y finalizar
                  los trámites. Si no estás conforme o no se concreta, se te
                  reintegrará el importe completo de la seña."
                  />
                </FormContainer>

                {step === 3 && (
                  <FormContainer color="white">
                    <h3>Confirmá tu reserva</h3>
                    <p>
                      {customer.name}, estas a punto de reservar online tu
                      próximo{' '}
                      <strong>
                        0km {vehicle.name}, {vehicle.color}
                      </strong>{' '}
                      .
                    </p>

                    <PriceTable
                      vehicle={vehicle}
                      showTotal
                      promotion={promotion}
                    >
                      <div className="priceTable__row">
                        <div className="priceTable__item">Seña a pagar</div>
                        <div className="priceTable__price">{currency(this.props.bookingPrice)}</div>
                      </div>
                      <div className="priceTable__row">
                        <div className="priceTable__item">Pago de seña</div>
                        <div className="priceTable__price">
                          <span style={{ textTransform: 'capitalize' }}>
                            {payment.card}
                          </span>{' '}
                          en {payment.installments} cuota(s)
                        </div>
                      </div>
                    </PriceTable>

                    <FormActions>
                      {purchaseError !== false && (
                        <div>
                          <Message
                            type="error"
                            title="Error realizando el pago"
                            detail={purchaseError}
                            actionText="Cambiar datos de pago"
                            actionLink={() => this.goToStep(2)}
                          />
                          <br /> <br />
                        </div>
                      )}

                      {processing === false && (
                        <Button
                          arrow
                          onClick={this.submitCardInformation.bind(this)}
                        >
                          Confirmar reserva
                        </Button>
                      )}

                      <br />
                      <br />

                      {processing === false && (
                        <Button
                          arrow
                          back
                          color="white"
                          onClick={() => this.goToStep(2)}
                        >
                          Cambiar pago de seña
                        </Button>
                      )}

                      {processing && <Loader />}
                    </FormActions>
                  </FormContainer>
                )}
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.stock.loading,
    purchasing: state.stock.purchasing,
    vehicle: state.stock.item,
    purchaseError: state.stock.purchaseError,
    purchaseSuccess: state.stock.purchaseSuccess,
    promotion: state.stock.promotion,
    bookingPrice: state.parameters.booking_price || 200000,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchStockItem: id => {
      dispatch(fetchStockItem(id));
    },
    sendPurchaseRequest: (id, data) => {
      dispatch(purchaseStockItem(id, data));
    },
    init: () => {
      dispatch(initPurchase());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buy);
