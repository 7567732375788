import { combineReducers } from 'redux';

import families from './families';
import stock from './stock';
import parameters from './parameters';
import plans from './plans';

const rootReducer = combineReducers({
  families,
  stock,
  parameters,
  plans
});

export default rootReducer;
