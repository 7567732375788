import { useState } from 'react';
import 'whatwg-fetch';
import FormFieldset from 'components/molecules/FormFieldset';
import FormTextField from 'components/molecules/FormTextField';
import FormActions from 'components/molecules/FormActions';
import Button from 'components/atoms/Button';
import FormContainer from 'components/organisms/FormContainer';
import { Link } from 'react-router-dom';

const ValidateCoupon = () => {
  const [code, setCode] = useState('');
  const [plan, setPlan] = useState(null);
  const [error, setError] = useState(false);

  function handleChange(e) {
    setCode(e.currentTarget.value);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setError(false);

    fetch(`${process.env.REACT_APP_INTERNAL_API_URL}/plans/validate`, {
      method: 'POST',
      body: JSON.stringify({ code }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        if (!response) {
          setError(true);
          return;
        }

        setPlan(response);
      })
      .catch(e => {
        setError(true);

        console.error(e);
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer arrow>
        <h3>Código de beneficios</h3>

        {!plan && (
          <div>
            <p>Si tu asesor te generó un código, validalo aquí.</p>
            <FormFieldset>
              <FormTextField
                label="Tu código de beneficios"
                name="code"
                value={code}
                onChange={handleChange}
                status={error ? 'error' : undefined}
                error={
                  error ? 'El código es incorrecto o ha vencido.' : undefined
                }
              />
            </FormFieldset>
            <FormActions>
              <Button disabled={!code} type="submit">
                Validar
              </Button>
            </FormActions>
          </div>
        )}
        {plan && (
          <>
            <p>
              <strong>¡Tu código es válido!</strong>
              <br />
              Continúa con la suscripción para obtener los beneficios <br />
              <br />
            </p>
            <Link
              to={`/planes-de-ahorro/suscribirse/${plan.plan}/con-beneficios/${code}`}
            >
              <Button>Subscribirme</Button>
            </Link>
          </>
        )}
      </FormContainer>
    </form>
  );
};

export default ValidateCoupon;
