export function number(input) {
  return input ? input.toLocaleString('es-AR') : '';
}

export function currency(input) {
  return input
    ? parseFloat(parseFloat(input).toFixed(2)).toLocaleString('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumSignificantDigits: 2
      })
    : '';
}

export function url(input) {
  return input ? encodeURI(input.toLowerCase().replace(/[\s/]+/g, '-')) : '';
}
