const priceRequested = sessionStorage
  ? sessionStorage.getItem('priceRequested')
  : false;

const defaultState = {
  stock: null,
  loading: false,
  purchasing: false,
  purchaseSuccess: false,
  purchaseError: false,
  requestingPrice: false,
  priceRequested,
  priceRequestError: false,
  offers: null,
  item: null,
  checkingPromotion: false,
  promotion: undefined,
  availablePromotion: undefined
};

const specialPricePeriod = (new Date()).toISOString() <= '2023-10-31';

export default function models(state = defaultState, action) {
  switch (action.type) {
    case 'REQUEST_STOCK':
      return Object.assign({}, state, { loading: true, stock: null });

    case 'RESET_STOCK':
      return Object.assign({}, state, { stock: null });

    case 'RECEIVE_STOCK':
      return Object.assign({}, state, {
        loading: false,
        stock: action.stock.map(vehicle => {
          if (!specialPricePeriod) {
            return vehicle;
          }

          if (vehicle.code === 'H6A2BAR----1301') {
            vehicle.govPrice = true;
          }

          return vehicle;
        })
      });

    case 'REQUEST_PRICE':
      return Object.assign({}, state, { requestingPrice: true });
    case 'PRICE_REQUESTED':
      sessionStorage.setItem('priceRequested', true);

      return Object.assign({}, state, {
        requestingPrice: false,
        priceRequested: true
      });
    case 'PRICE_REQUEST_ERROR':
      return Object.assign({}, state, {
        priceRequestError: true,
        requestingPrice: false
      });

    case 'REQUEST_OFFERS':
      return Object.assign({}, state, { loading: true, offers: null });

    case 'OFFERS_RECEIVED':
      return Object.assign({}, state, {
        loading: false,
        offers: action.offers
      });

    case 'REQUEST_OFFERS_ERROR':
      return Object.assign({}, state, {
        loading: false,
        offers: []
      });

    case 'REQUEST_ITEM':
      return Object.assign({}, state, { loading: true, offers: null });

    case 'ITEM_RECEIVED':
      const item = action.item;
      if (specialPricePeriod && item.code === 'H6A2BAR----1301') {
        item.govPrice = true;
      }

      return Object.assign({}, state, {
        loading: false,
        item: action.item
      });

    case 'REQUEST_ITEM_ERROR':
      return Object.assign({}, state, {
        loading: false,
        item: false
      });

    case 'INIT_PURCHASE':
      return Object.assign({}, state, {
        purchasing: false,
        purchaseError: false,
        purchaseSuccess: false
      });

    case 'REQUEST_PURCHASE':
      return Object.assign({}, state, {
        purchasing: true,
        purchaseError: false
      });

    case 'PURCHASE_SUCCESSFUL':
      return Object.assign({}, state, {
        purchasing: false,
        purchaseSuccess: true,
        purchaseError: false,
        promotion: undefined
      });

    case 'REQUEST_PURCHASE_ERROR':
      return Object.assign({}, state, {
        purchasing: false,
        purchaseError: action.error
      });

    case 'PROMOTION_CODE_INIT':
      return { ...state, promotion: undefined };

    case 'PROMOTION_CODE_REQUESTED':
      return {
        ...state,
        checkingPromotion: true,
        promotion: undefined
      };

    case 'PROMOTION_CODE_REQUEST_FAILED':
      return {
        ...state,
        checkingPromotion: false,
        promotion: null
      };

    case 'PROMOTION_CODE_SUCCESSFUL':
      return {
        ...state,
        checkingPromotion: false,
        promotion: action.promotion
      };

    case 'PROMOTION_CODE_DISCOVER_REQUESTED':
      return {
        ...state,
        availablePromotion: undefined
      };

    case 'PROMOTION_CODE_DISCOVER_SUCCESSFUL':
      return {
        ...state,
        availablePromotion: action.promotion
      };

    default:
      return state;
  }
}
