import { Component } from 'react';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';

import heroImage from 'images/models/march.png';

import './BuyPlanSuccess.scss';

class BuyPlanSuccess extends Component {
  render() {
    return (
      <div className="buyPlanSuccess">
        <MobileNavigation />
        <Header />
        <div className="unitDetail container">
          <div className="unitDetail__hero">
            <div className="unitDetail__hero__data">
              <h4>Te suscribiste al plan Nissan</h4>
              <h1>March Active</h1>
              <p>
                Operatoria: <strong>100%</strong>
                <br />
                Meses: <strong>84</strong>
                <br />
                Precio / Valor Móvil: <strong>$ 418.200</strong>
              </p>
            </div>
            <div className="unitDetail__hero__image">
              <img src={heroImage} alt="Kicks" />
            </div>
          </div>
          <div className="unitDetail__buy">
            <div className="unitDetail__buyHelp">
              <h2>¡Felicitaciones!</h2>
              <p>
                <strong>Te suscribiste al plan Nissan March Advance.</strong>
              </p>
              <p>
                Te contactaremos para continuar con los con el proceso de
                subscripción.
              </p>
              <p>Cualquier duda que tengas llamanos al +54 261 4439300.</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BuyPlanSuccess;
