import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'components/atoms/Button';
import './VehiclePromotionCard.scss';

import { currency } from 'helpers/format';
import { getImage as getVehicleImage, calculatePrice } from 'helpers/vehicle';

class VehiclePromotionCard extends Component {
  render() {
    const { size, vehicle, link, callToAction } = this.props;

    let vehicleImage = getVehicleImage(vehicle.family || vehicle.name);

    return (
      <div
        className={'vehicleCard ' + (size ? 'vehicleCard-' + size + ' ' : '')}
      >
        <div className="vehiclePromotionCard__container">
          <div className="vehiclePromotionCard__title">
            <h3>{vehicle.name}</h3>
          </div>
          {vehicle.price > 0 && (
            <div className="vehiclePromotionCard__price">
              <h5>Precio final</h5>
              <h2>{currency(calculatePrice(vehicle))}</h2>
            </div>
          )}
          {vehicle.start_price > 0 && (
            <div className="vehiclePromotionCard__price">
              <h5>Desde</h5>
              <h2>{currency(vehicle.start_price)}</h2>
            </div>
          )}
          {(vehicle.web_discount > 0 ||
            vehicle.special_discount > 0 ||
            vehicle.model_year_discount > 0) && (
            <div className="vehiclePromotionCard__priceDetail">
              {vehicle.price > 0 && (
                <div className="vehiclePromotionCard__listPrice">
                  <div className="vehiclePromotionCard__discount__item">
                    Precio de lista: <strong>{currency(vehicle.price)}</strong>
                  </div>
                </div>
              )}
              <div className="vehiclePromotionCard__discounts">
                {vehicle.web_discount > 0 && (
                  <div className="vehiclePromotionCard__discount__item">
                    Descuento Web:{' '}
                    <strong>-{currency(vehicle.web_discount)}</strong>
                  </div>
                )}
                {vehicle.model_year_discount > 0 && (
                  <div className="vehiclePromotionCard__discount__item">
                    Descuento por MY:{' '}
                    <strong>-{currency(vehicle.model_year_discount)}</strong>
                  </div>
                )}
                {vehicle.special_discount > 0 && (
                  <div className="vehiclePromotionCard__discount__item">
                    Descuento especial:{' '}
                    <strong>-{currency(vehicle.special_discount)}</strong>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="vehiclePromotionCard__image">
            <img src={vehicleImage} alt="Model" />
          </div>
          <div className="vehiclePromotionCard__actions">
            <NavLink to={link}>
              <Button arrow="true" color="gray" type="full">
                {callToAction}
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default VehiclePromotionCard;
