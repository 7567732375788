import { Component } from 'react';
import { connect } from 'react-redux';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';
import Message from 'components/atoms/Message';
import Wizard from 'components/molecules/Wizard';
import WizardStep from 'components/atoms/WizardStep';
import FormContainer from 'components/organisms/FormContainer';
import FormFieldset from 'components/molecules/FormFieldset';
import FormTextField from 'components/molecules/FormTextField';
import FormActions from 'components/molecules/FormActions';
import Loader from 'components/atoms/Loader';
import NoPlan from 'containers/PlanBuy/NoPlan';
import DisplayPlanCoupon from 'components/organisms/PlanCoupon/DisplayPlanCoupon';
import fetch from 'isomorphic-fetch';
import { INTERNAL_API_URL } from 'helpers/apiURL';
import { fetchPlan, subscribe, initSubscription } from 'actions/plans';
import { getHero as getVehicleImage } from 'helpers/vehicle';
import { currency } from 'helpers/format';

class PlanBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      subscription: undefined,
      processing: false,
      paymentAmount: 2000
    };

    this.handlePaymentAmountChange = this.handlePaymentAmountChange.bind(this);
    this.getTotalPaymentAmount = this.getTotalPaymentAmount.bind(this);
    this.goToStep = this.goToStep.bind(this);
  }

  componentDidMount() {
    this.props.fetchPlan(this.props.match.params.id);

    fetch(
      `${INTERNAL_API_URL}/plan/subscription?id=${this.props.match.params.subscription}`,
      {
        method: 'POST'
      }
    )
      .then(response => response.json())
      .then(response => this.setState({ subscription: response.data }))
      .catch(e => {
        console.error(e);
        this.setState({ subscription: null });
      });
  }

  goToStep(step) {
    this.setState({ step });
  }

  handlePaymentAmountChange(e) {
    this.setState({ paymentAmount: parseFloat(e.currentTarget.value) });
  }

  getTotalPaymentAmount() {
    return this.state.subscription.payment_amount + this.state.paymentAmount;
  }

  submit(e) {
    this.setState({ processing: true, step: 2 });

    let payment_amount = this.getTotalPaymentAmount();
    let payed_installments = 0;

    if (payment_amount === this.props.plan.initial_installment) {
      payed_installments = 1;
    }

    if (payment_amount >= this.props.plan.first_and_second_installments) {
      payed_installments = 2;
    }

    this.props.sendPurchaseRequest({
      subscription: {
        ...this.state.subscription,
        payment_amount: this.state.paymentAmount,
        payed_installments
      },
      plan: this.props.plan
    });
  }

  render() {
    const {
      plan,
      loading,
      purchaseError,
      purchaseSuccess,
      processing
    } = this.props;

    const { subscription, step, paymentAmount } = this.state;

    if (loading || plan === undefined || subscription === undefined) {
      return <Loader />;
    }

    if (plan === null || subscription === null) {
      return this.renderNoPlan();
    }

    return (
      <div className="PlanBooking">
        <MobileNavigation />
        <Header />
        <div className="unitDetail container">
          <div className="unitDetail__hero">
            <div className="unitDetail__hero__data">
              <h4>Señar Plan de Ahorro</h4>
              <h1>{plan.name}</h1>
              <p>
                Modelo: <strong>{plan.model}</strong>
                <br />
                Operatoria: <strong>{plan.integration}</strong>
                <br />
                Meses: <strong>{plan.installments}</strong>
                <br />
                Precio / Valor Móvil:{' '}
                <strong>{currency(plan.vehicle_price)}</strong>
                <br />
                Cuota de adhesión:{' '}
                <strong>{currency(plan.initial_installment)}</strong>
                <br />
                {subscription.code && (
                  <>
                    Cuota de adhesión: <strong>{subscription.code}</strong>
                  </>
                )}
              </p>
              {subscription.coupon && (
                <DisplayPlanCoupon planCoupon={subscription.coupon} />
              )}
            </div>
            <div className="unitDetail__hero__image">
              <img src={getVehicleImage(plan.family)} alt={plan.model} />
            </div>
          </div>

          {purchaseSuccess === true && (
            <div className="unitDetail__buy">
              <div className="unitDetail__buyHelp">
                <h2>Ya casi terminamos.</h2>
                <p>
                  <strong>
                    Recibimos tu pedido de pago al plan {plan.name}.
                  </strong>
                </p>
                <p>
                  Una vez hecho el pago completo acercate a nuestro
                  concesionario para terminar de concretar la subscripción. Te
                  esperamos en San Martín Sur 719, Godoy Cruz, Mendoza.
                </p>
                <p>Cualquier duda que tengas llamanos al +54 261 4439300.</p>
              </div>
            </div>
          )}

          <div className="unitDetail__buy">
            <div className="unitDetail__buy__step" id="personal-info">
              <Wizard>
                <WizardStep
                  status={step === 1 ? 'active' : 'complete'}
                  stepName="Cuotas y confirmación"
                  stepNumber="1"
                />
                <WizardStep
                  status={step === 2 ? 'active' : ''}
                  stepName="Pago de tu subscripción"
                  stepNumber="2"
                />
              </Wizard>

              {step === 1 && (
                <FormContainer color="white">
                  ¡Hola {subscription.name}!<h3>Estado de tu subscripción</h3>
                  <p>
                    Importe abonado hasta el momento:{' '}
                    {currency(subscription.payment_amount)}
                  </p>
                  <p>
                    Saldo pendiente cuota inicial:{' '}
                    {currency(
                      subscription.plan_first_installment -
                        subscription.payment_amount
                    )}
                    <br />
                    Saldo pendiente cuota inicial y primera cuota:{' '}
                    {currency(
                      subscription.plan_first_and_second_installment -
                        subscription.payment_amount
                    )}
                  </p>
                  <h3>Ingresá el importe a pagar</h3>
                  <FormFieldset>
                    <FormTextField
                      label="Ingresá el importe a pagar"
                      size="medium"
                      type="number"
                      name="payment_amount"
                      value={paymentAmount}
                      helpText={`Ingresá un importe de hasta ${currency(
                        plan.initial_installment
                      )} y ${currency(
                        plan.first_and_second_installments
                      )} para la cuota inicial, o inicial y primer cuota.`}
                      onChange={this.handlePaymentAmountChange}
                      max={plan.first_and_second_installments}
                      min={2000}
                      error={
                        parseFloat(this.getTotalPaymentAmount()).toFixed(2) ===
                          plan.initial_installment ||
                        parseFloat(this.getTotalPaymentAmount()).toFixed(2) ===
                          plan.first_and_second_installments
                          ? 'El importe corresponde al importe de una o más cuotas, por lo que se considerará como subscripción y no seña.'
                          : null
                      }
                    />
                  </FormFieldset>
                  <FormActions>
                    <Button
                      arrow
                      disabled={!paymentAmount}
                      onClick={() => this.submit()}
                    >
                      Continuar
                    </Button>
                  </FormActions>
                  <Message
                    title="Subscribite con tranquilidad"
                    type="info"
                    detail={`Una vez completo el formulario y realizado el pago total, tenés
                  48 horas para comunicarte con nuestro concesionario y finalizar
                  los trámites. Si no estás conforme o no se concreta, se te
                  reintegrará el importe completo.`}
                  />
                </FormContainer>
              )}

              {step === 2 && processing && <Loader />}

              {step === 2 && purchaseSuccess && (
                <FormContainer color="white">
                  <h3>Confirmá tu suscripción</h3>
                  <p>
                    {subscription.name}, estás a punto de realizar un pago
                    parcial para un Nissan Plan, realizando un{' '}
                    <strong>
                      pago con tarjeta de crédito o débito por un importe de{' '}
                      {currency(paymentAmount)}.
                    </strong>{' '}
                    <br />
                    Si no pagaste el importe total, recibirás un e-mail con un
                    acceso directo para continuar tu subscripción.
                  </p>

                  <iframe
                    title="Pago con LinkingPOS"
                    src={`https://linkingpos.com/external_payments/new?access_token=ZVwLKwS7hugny35TxvSewV9o&import=${paymentAmount}&client_ref=${subscription.transaction.code}`}
                    style={{
                      width: '100%',
                      height: '820px',
                      overflowY: 'hidden'
                    }}
                    scrolling="no"
                    frameBorder="0"
                  ></iframe>
                </FormContainer>
              )}

              {step === 2 && purchaseError && (
                <FormContainer color="white">
                  <h3>Error al solicitar la subscripción</h3>
                  <p>
                    Por favor intentalo nuevamente más tarde o revisá tus datos
                    personales.
                  </p>

                  <FormActions>
                    <Button
                      arrow
                      back
                      color="white"
                      onClick={() => this.goToStep(1)}
                    >
                      Reintentar
                    </Button>
                  </FormActions>
                </FormContainer>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  renderNoPlan() {
    return <NoPlan />;
  }
}

const mapStateToProps = state => {
  return {
    loading: state.plans.loading,
    purchasing: state.plans.purchasing,
    plan: state.plans.plan,
    purchaseError: state.plans.purchaseError,
    purchaseSuccess: state.plans.purchaseSuccess,
    transaction: state.plans.transaction
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlan: id => {
      dispatch(initSubscription());
      dispatch(fetchPlan(id));
    },
    sendPurchaseRequest: data => {
      dispatch(subscribe(data));
    },
    reset: () => {
      dispatch(initSubscription());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanBooking);
