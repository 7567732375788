import { Component } from 'react';
import { connect } from 'react-redux';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import VehicleCard from 'components/molecules/VehicleCard';
import { fetchList } from 'actions/families';
import { url } from 'helpers/format';

import './Vehicles.scss';

class Vehicles extends Component {
  constructor(props) {
    super(props);

    props.fetch();
  }

  render() {
    return (
      <div>
        <MobileNavigation />
        <Header />
        <div className="vehicles container">
          <h1>Vehículos</h1>
          <div className="vehicleList">
            {this.props.loading && <div>Consultando familias...</div>}

            {this.props.families.map((family, i) => {
              return (
                <VehicleCard
                  key={i}
                  vehicle={family}
                  link={`/tu-proximo-nissan/${family.id}/${url(family.name)}`}
                />
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    families: state.families.list,
    loading: state.families.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: () => {
      dispatch(fetchList());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
