import { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';

class ConfirmedShift extends Component {
  render() {
    return (
      <div>
        <MobileNavigation />
        <Header />
        <div className="unitDetail container">
          <div className="unitDetail__buy">
            <div className="unitDetail__buyHelp">
              <h2>Turnos de taller</h2>
              <p>
                <strong>Turno confirmado exitosamente.</strong>
              </p>
              <p>Te esperamos en la fecha y hora indicada en el email.</p>

              <Link to="/">
                <Button arrow color="white">
                  Volver
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ConfirmedShift;
