import versaHeroImage from 'images/models/versa-v-drive.jpg';
import newVersaHeroImage from 'images/models/nuevo-versa.png';
import newSentraHeroImage from 'images/models/nuevo-sentra.png';
import noteHeroImage from 'images/models/note.png';
import muranoHeroImage from 'images/models/murano.png';
import marchHeroImage from 'images/models/march.png';
import kicksHeroImage from 'images/models/kicks.png';
import newKicksHeroImage from 'images/models/nuevo kicks.jpg';
import fronterHeroImage from 'images/models/frontier.png';
import xtrailHeroImage from 'images/models/xtrail.png';

import versaImage from 'images/models/small/versa-v-drive.jpg';
import newVersaImage from 'images/models/small/nuevo-versa.jpg';
import newSentraImage from 'images/models/small/nuevo-sentra.jpg';
import noteImage from 'images/models/small/note.jpg';
import muranoImage from 'images/models/small/murano.jpg';
import marchImage from 'images/models/small/march.jpg';
import kicksImage from 'images/models/small/kicks.png';
import newKicksImage from 'images/models/small/nuevo kicks.png';
import fronterImage from 'images/models/small/frontier.jpg';
import xtrailImage from 'images/models/small/xtrail.jpg';

export function getHero(name) {
  switch (name.toLowerCase()) {
    case 'versa v-drive':
    case 'versa v drive':
      return versaHeroImage;

    case 'nuevo versa':
    case 'new versa':
    case 'versa':
      return newVersaHeroImage;

    case 'sentra':
    case 'nuevo sentra':
      return newSentraHeroImage;

    case 'note':
      return noteHeroImage;

    case 'murano':
      return muranoHeroImage;

    case 'march':
      return marchHeroImage;

    case 'nuevo kicks':
    case 'nueva kicks':
      return newKicksHeroImage;

    case 'kicks':
      return kicksHeroImage;

    case 'nueva frontier':
      return fronterHeroImage;
    
    case 'nueva x-trail':
    case 'x-trail':
    case 'xtrail':
      return xtrailHeroImage;
    default:
      return '';
  }
}

export function getImage(name) {
  switch (name.toLowerCase()) {
    case 'versa v drive':
    case 'versa v-drive':
      return versaImage;
    case 'new versa':
    case 'nuevo versa':
    case 'versa':
      return newVersaImage;

    case 'sentra':
    case 'nuevo sentra':
      return newSentraImage;

    case 'note':
      return noteImage;

    case 'murano':
      return muranoImage;

    case 'march':
      return marchImage;

    case 'nuevo kicks':
    case 'nueva kicks':
      return newKicksImage;

    case 'kicks':
      return kicksImage;

    case 'nueva frontier':
      return fronterImage;

    case 'nueva x-trail':
    case 'x-trail':
    case 'xtrail':
      return xtrailImage;
    default:
      return '';
  }
}

export function calculatePrice(vehicle, promotion) {
  const {
    price,
    special_discount,
    web_discount,
    model_year_discount,
    preparation_cost,
    license_cost,
    delivery_cost
  } = vehicle;

  let fullPrice =
    parseFloat(price) +
    parseFloat(preparation_cost) +
    parseFloat(license_cost) +
    parseFloat(delivery_cost || 0);

  if (special_discount > 0) {
    fullPrice -= special_discount;
  } else if (model_year_discount > 0) {
    fullPrice -= model_year_discount;
  } else if (web_discount > 0) {
    fullPrice -= web_discount;
  }

  if (promotion) {
    if (promotion.money_discount > 0) {
      fullPrice -= promotion.money_discount;
    } else {
      fullPrice -= (price * promotion.percentage_discount) / 100;
    }
  }

  return fullPrice;
}
