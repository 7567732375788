import fetch from 'isomorphic-fetch';

/* ------------------- Request price ------------------- */
export const requestParameters = () => ({ type: 'REQUEST_PARAMETERS' });

export const fetchParameters = data => {
  return function(dispatch) {
    dispatch(requestParameters());

    return fetch(`https://territorioyacopini.com.ar/api/v2/nissan/parameters`, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(response => dispatch(popupReceived(response)));
  };
};

export const popupReceived = response => {
  if (response && sessionStorage.getItem('popup_displayed')) {
    response.popup = [];
  }

  if (response && response.popups.length) {
    sessionStorage.setItem('popup_displayed', true);
  }

  return {
    type: 'PARAMETERS_RECEIVED',
    payload: response,
  };
};
