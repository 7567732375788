import { Component } from 'react';
import { connect } from 'react-redux';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import VehicleVersionCard from 'components/molecules/VehicleVersionCard';
import Button from 'components/atoms/Button';
import UnitsContainer from 'components/organisms/UnitsContainer';
import UnitItem from 'components/molecules/UnitItem';
import PriceForm from 'components/organisms/PriceForm';
import Loader from 'components/atoms/Loader';

import { fetchFamily } from 'actions/families';
import { fetchStock, resetStock } from 'actions/stock';
import { getHero as getVehicleImage } from 'helpers/vehicle';

import './VehicleDetail.scss';

class VehicleDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPricesForm: false,
      focused: false,
      arrowPosition: 'left'
    };

    props.getFamily(props.match.params.family_id);

    props.resetStock();
  }

  focus() {
    this.setState({
      showPricesForm: true,
      focused: true
    });
  }

  setArrowPosition(blockNumber) {
    let arrowPosition;
    switch ((blockNumber % 3) + 1) {
      case 1:
        arrowPosition = 'left';
        break;
      case 2:
        arrowPosition = 'center';
        break;
      case 3:
        arrowPosition = 'right';
        break;
      default:
        arrowPosition = 'left';
    }

    this.setState({
      arrowPosition
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.focused === true && !prevState.focused) {
      document.getElementById('price-request-name')?.focus();
    }
  }

  displayPriceForm() {
    this.setState({
      showPricesForm: !this.state.showPricesForm,
      focused: !this.state.focused
    });

    if (!this.state.showPricesForm) {
      this.focus();
    }
  }

  render() {
    const {
      family,
      loadingFamily,
      stock,
      loadingStock,
      priceRequested
    } = this.props;

    const { showPricesForm } = this.state;

    let vehicleImage;

    if (family) {
      vehicleImage = getVehicleImage(family.name);
    }

    return (
      <div>
        <MobileNavigation />
        <Header />

        {loadingFamily && (
          <div className="vehicleDetail container">
            <div className="vehicleDetail__hero">
              <div className="vehicleDetail__hero__data">
                <Loader />
              </div>
            </div>
          </div>
        )}

        {!loadingFamily && family && (
          <div className="vehicleDetail container">
            <div className="vehicleDetail__hero">
              <div className="vehicleDetail__hero__data">
                <h1>{family.name}</h1>

                {!priceRequested && (
                  <Button arrow onClick={() => this.displayPriceForm()}>
                    Ver Precios
                  </Button>
                )}
              </div>
              <div className="vehicleDetail__hero__image">
                <img src={vehicleImage} alt={family.name} />
              </div>
            </div>

            {showPricesForm && !priceRequested && (
              <div className="showPriceForm">
                <PriceForm family={family} />
              </div>
            )}
            <div className="vehicleDetail__versions">
              <h2>Versiones</h2>
              {/* // para que muestre los precios y el boton de unidades el vehicleVersionCard tiene que tener la propiedad "showPrice" */}
              <div className="vehicleVersionsList">
                {family.subfamilies.map((subfamily, i) => {
                  return (
                    <VehicleVersionCard
                      key={subfamily.id}
                      family={family}
                      vehicle={subfamily}
                      showPrice={priceRequested}
                      stockCallback={() => {
                        this.props.getStock(family.id, subfamily.id);

                        this.setArrowPosition(i);
                      }}
                      priceCallback={() => this.displayPriceForm()}
                    />
                  );
                })}

                {/* // El link de "Ver caracteristicas" Deberia llevar a la web de nissan argentina o algo asi, asi nos evitamos ese quilombo, al menos por ahora */}

                {/* // El UnitsContainer aparece al hacer click en el boton de "Ver unidades" de una version, y el arrowPosition tiene que apuntar al bloque correspondiente */}

                {loadingStock && <Loader />}

                {stock && stock.length >= 0 && (
                  <UnitsContainer
                    vehicle={family.name}
                    arrowPosition={this.state.arrowPosition}
                    closeCallback={() => this.props.resetStock()}
                    units={stock.length}
                  >
                    {/* // Aca va el listado de unidades disponibles, si no hay unidades deja el UnitsContainer vacio y muestra un mensaje */}
                    {stock.length === 0 && (
                      <p>En este momento no contamos con stock web para este
                      vehículo, pero podés consultarnos personalmente o por
                      teléfono.</p>
                    )}
                    {stock.map(vehicle => {
                      return (
                        <UnitItem vehicle={vehicle} key={vehicle.id}>
                          {vehicle.doors}. Color: {vehicle.color}. <br />
                          Número de Chasis: {vehicle.chasis}.
                        </UnitItem>
                      );
                    })}
                  </UnitsContainer>
                )}
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    family: state.families.family,
    loadingFamily: state.families.loading,
    loadingStock: state.stock.loading,
    stock: state.stock.stock,
    priceRequested: state.stock.priceRequested
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFamily: id => {
      dispatch(fetchFamily(id));
    },
    getStock: (familyId, subfamilyId) => {
      dispatch(fetchStock(familyId, subfamilyId));
    },
    resetStock: () => {
      dispatch(resetStock());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetail);
