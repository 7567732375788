import { url } from 'helpers/format';

const floatAttributes = ['vehicle_price'];

export default class Plan {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (floatAttributes.indexOf(key) >= 0) {
          this[key] = parseFloat(data[key]);
        } else {
          this[key] = data[key];
        }
      }
    }
  }

  set initial_installment(value) {
    this._initial_installment = parseFloat(value).toFixed(2);
  }

  set first_installment(value) {
    this._first_installment = parseFloat(value).toFixed(2);
  }

  set second_installment(value) {
    this._second_installment = parseFloat(value).toFixed(2);
  }

  get initial_installment() {
    return this._initial_installment;
  }

  get first_installment() {
    return this._first_installment;
  }

  get second_installment() {
    return this._second_installment;
  }

  get first_and_second_installments() {
    return (
      parseFloat(this.initial_installment) + parseFloat(this.first_installment)
    ).toFixed(2);
  }

  get first_three_installments() {
    return (
      parseFloat(this.initial_installment) + parseFloat(this.first_installment) + parseFloat(this.second_installment)
    ).toFixed(2);
  }

  get calculated_installment() {
    return (this.vehicle_price / this.installments).toFixed(2);
  }

  get detail_link() {
    return `/planes-de-ahorro/detalle/${this.id}/${url(this.name)}`;
  }

  get simulate_link() {
    return `/planes-de-ahorro/simulador/${this.id}/${url(this.name)}`;
  }

  get subscribe_link() {
    return `/planes-de-ahorro/suscribirse/${this.id}/${url(this.name)}`;
  }

  get installments_link() {
    return `/planes-de-ahorro/cuotas/${this.id}/${url(this.name)}`;
  }

  calculateInstallments(increase) {
    return this.installment_list.map((installment, i) => ({
      installment: i + 1,
      amount:
        (parseFloat(installment.pure_installment) +
          parseFloat(installment.administrative_charge) +
          parseFloat(installment.life_insurance_charge) +
          parseFloat(installment.stamping_charge) +
          parseFloat(installment.inscription_charge)) *
        (1 + increase / 100)
    }));
  }
}
