import './FormContainer.scss';

export default ({ arrow, color, children, ...etc }) => (
  <div
    {...etc}
    className={
      'formContainer ' +
      (arrow ? 'formContainer-arrow ' : '') +
      (color ? 'formContainer-' + color + ' ' : '')
    }
  >
    <div className="formContainer__content">{children}</div>
  </div>
);
