import { Component } from 'react';
import { connect } from 'react-redux';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import Loader from 'components/atoms/Loader';
import VehiclePromotionCard from 'components/molecules/VehiclePromotionCard';
import FamilyPromotionCard from 'components/molecules/FamilyPromotionCard';
import UnitsContainer from 'components/organisms/UnitsContainer';
import UnitItem from 'components/molecules/UnitItem';

import { fetchOffers } from 'actions/stock';
import { fetchList as fetchFamilies } from 'actions/families';
import { url } from 'helpers/format';

import './Promotions.scss';

class Promotions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleOfferStock: null
    };

    props.fetchOffers();
    props.fetchFamilies();
  }

  groupByFamily(offers) {
    if (!offers) {
      return false;
    }

    const familyIndex = {};
    const familyOffers = [];

    offers.forEach(offer => {
      let index = familyIndex[offer.family];

      if (index === undefined) {
        index = familyOffers.length;

        familyOffers.push({
          family: offer.family,
          offers: []
        });

        familyIndex[offer.family] = index;
      }

      familyOffers[index].offers.push(offer);
    });

    return familyOffers.map(familyOffer => {
      familyOffer.highestDiscount = familyOffer.offers.reduce((best, offer) => {
        return offer.special_discount > best ? offer.special_discount : best;
      }, familyOffer.offers[0].special_discount);

      return familyOffer;
    });
  }

  render() {
    const { loading, offers, families } = this.props;

    const { visibleOfferStock } = this.state;

    const familyOffers = this.groupByFamily(offers);

    if (loading) {
      return (
        <div>
          <MobileNavigation />
          <Header />

          <Loader />
        </div>
      );
    }

    return (
      <div>
        <MobileNavigation />
        <Header />
        <div className="promotions container">
          <div className="promotions__title">
            <h1>Oportunidades</h1>
          </div>

          <div className="promotions__opportunities">
            <h2>Oportunidades del día</h2>
            <p>
              Vehiculos únicos con condiciones especiales sujeto a la revisión y
              aprobación del cliente.
            </p>
            <div className="vehicleList">
              {familyOffers &&
                familyOffers.map(familyOffer => (
                  <FamilyPromotionCard
                    key={familyOffer.family}
                    vehicle={familyOffer}
                    callToAction="Ver unidades"
                    buttonCallback={() =>
                      this.setState({ visibleOfferStock: familyOffer })
                    }
                  />
                ))}
            </div>

            {visibleOfferStock && (
              <UnitsContainer
                vehicle={visibleOfferStock.name}
                closeCallback={() => this.setState({ visibleOfferStock: null })}
              >
                {/* // Aca va el listado de unidades disponibles, si no hay unidades deja el UnitsContainer vacio y muestra un mensaje */}

                {visibleOfferStock.offers.map(vehicle => {
                  return (
                    <UnitItem vehicle={vehicle} key={vehicle.id}>
                      {vehicle.doors}. Color: {vehicle.color}.<br />
                      Número de chasis: {vehicle.chasis}
                    </UnitItem>
                  );
                })}
                <div className="promotions__disclamers">
                  <p>
                    *Los precios no incluyen gastos de patentamiento ni entrega.
                  </p>
                  <p>
                    *Vehiculos únicos con condiciones especiales sujeto a la
                    revisión y aprobación del cliente.
                  </p>
                </div>
              </UnitsContainer>
            )}
          </div>
          <div className="promotions__discounts">
            <h2>Descuentos web</h2>
            <p>Precios especiales solo comprando por esta web.</p>
            <div className="vehicleList">
              {families
                .filter(family => family.web_discount > 0)
                .map(family => (
                  <VehiclePromotionCard
                    key={family.id}
                    vehicle={family}
                    link={`/tu-proximo-nissan/${family.id}/${url(family.name)}`}
                    callToAction="Conocelo"
                  />
                ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.stock.loading,
    offers: state.stock.offers,
    families: state.families.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOffers: () => {
      dispatch(fetchOffers());
    },
    fetchFamilies: () => {
      dispatch(fetchFamilies());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
