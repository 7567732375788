import { Component } from 'react';
import Input from 'components/atoms/Input';
import Label from 'components/atoms/Label';
import './FormTextField.scss';

class FormTextField extends Component {
  render() {
    const {
      status,
      size,
      label,
      placeholder,
      helpText,
      error,
      inline,
      ...etc
    } = this.props;

    /* STATUS = "error", "disabled" */

    return (
      <div
        className={
          'formTextField ' +
          (size ? 'formTextField-' + size + ' ' : '') +
          (inline ? 'formTextField-inline ' : '') +
          (status ? 'formTextField-' + status + ' ' : '')
        }
      >
        <Label>{label}</Label>
        <Input placeholder={placeholder} {...etc} />
        {helpText && <div className="formTextField__helpText">{helpText}</div>}
        {(error || status === 'error') && (
          <div className="formTextField__error">{error}</div>
        )}
      </div>
    );
  }
}

export default FormTextField;
